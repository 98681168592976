import { Skeleton, Stack } from "@mui/material";

import { InSectionBorderedWrapper } from "common/components/InSectionBorderedWrapper/InSectionBorderedWrapper";

export const MapSectionSkeleton = () => {
  return (
    <InSectionBorderedWrapper
      title={<Skeleton variant="text" width="50%" />}
      subtitle={<Skeleton variant="text" width="50%" />}
    >
      <Stack spacing={4} direction="row" sx={{ minHeight: "300px" }}>
        <Stack direction="column" width="100%">
          <Skeleton
            variant="rectangular"
            height="100%"
            width="100%"
            sx={{ flexGrow: 1, display: "flex" }}
          />
        </Stack>
        <Stack spacing={4} direction="column" width="100%">
          <Skeleton variant="rectangular" height={150} width="100%" />
          <Skeleton variant="rectangular" height={350} width="100%" />
        </Stack>
      </Stack>
    </InSectionBorderedWrapper>
  );
};
