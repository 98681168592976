import {
  TopicDashboardChartSectionConfig,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionConfig
} from "../../dashboard/types";
import { PageConfigurator } from "../types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

export const statIds = {
  hospitalizationAdmissionsCount: "COVID19_HOSPITAL_ADMISSIONS_COUNT",
  hospitalizationCount: "COVID19_HOSPITALIZATIONS_COUNT",
  intensiveCareUnitCount: "COVID19_HOSPITALIZATIONS_ICU_COUNT"
};

type GenerateChartConfigParams = {
  identifier: string;
  title: string;
  id: string;
};
const generateChartConfig = ({
  identifier,
  title,
  id
}: GenerateChartConfigParams): TopicDashboardChartSectionConfig => ({
  granularity: MhcTimeSeriesGranularityEnum.Week,
  id,
  statIdConfigs: [
    {
      identifier,
      needSeries: true,
      needStat: false
    }
  ],
  timeScale: "weekly",
  title,
  type: "line"
});

const subSections: PageConfigurator<TopicDashboardSubsectionConfig> = (options) => {
  const locationId = options?.locationId;
  let kpiStatIds = [
    {
      identifier: statIds.hospitalizationCount,
      needSeries: true
    },
    {
      identifier: statIds.intensiveCareUnitCount,
      needSeries: true
    }
  ];
  if (locationId === "state") {
    kpiStatIds = [
      {
        identifier: statIds.hospitalizationAdmissionsCount,
        needSeries: true
      },
      ...kpiStatIds
    ];
  }
  return [
    {
      id: "keyData",
      kpiGroupConfig: {
        statIdConfigs: kpiStatIds,
        statIdsWithTrend: [
          statIds.hospitalizationAdmissionsCount,
          statIds.hospitalizationCount,
          statIds.intensiveCareUnitCount
        ]
      }
    },
    {
      id: "charts",
      chartConfigs: [
        generateChartConfig({
          identifier: statIds.hospitalizationCount,
          title: "Hospitalization Count",
          id: "hospitalization-count"
        }),
        generateChartConfig({
          identifier: statIds.hospitalizationAdmissionsCount,
          title: "Admissions count",
          id: "hospitalization-admissions-count"
        })
      ]
    }
  ];
};

export const covidHospitalizationsSections: PageConfigurator<TopicDashboardSectionConfig> = (
  options
) => [
  {
    id: "hospitalizations",
    title: "COVID-19 Hospitalizations",
    subSections: subSections(options)
  }
];
