import { Alert, AlertTitle, Typography } from "@mui/material";

const HowToUseDateSlider = () => (
  <Alert severity="info" variant="outlined" title="How to Use the Date Slider">
    <AlertTitle sx={{ mb: 0 }}>How to Use the Date Slider</AlertTitle>
    <Typography variant="body2">
      Use the slider above to investigate data by date. Make the date range larger or smaller by
      dragging on the handles on the left and right side.
    </Typography>
  </Alert>
);

export default HowToUseDateSlider;
