"use client";

import { useMemo } from "react";
import { usePathname } from "next/navigation";
import { Stack, Typography } from "@mui/material";

import { MhcSeason } from "graphqlApi/types";

import { addLinkOptionsToKpis, addSliderVisualization } from "../util";
import { updateKpiEnhancements } from "modules/Topics/util/elementHelpers/Covid/util/kpi";

import { default as DefaultKpis } from "../../common/components/Kpis";
import { KpiProps } from "common/components/KPI";
import { monthlyStats, statIds } from "../config";

type OverviewKpiProps = {
  kpis: KpiProps[];
  seasonStartDate: MhcSeason["startDate"];
  isCurrentSeason: boolean;
  isAllTime: boolean;
  seasonName: string;
  vaccineEndDate: MhcSeason["endDateForVaccine"];
  doseAuthorizationDate: MhcSeason["startDateForVaccine"];
};
const Kpis = ({
  kpis,
  seasonStartDate,
  isCurrentSeason,
  isAllTime = false,
  seasonName,
  vaccineEndDate,
  doseAuthorizationDate
}: OverviewKpiProps) => {
  const pathname = usePathname() ?? "";
  const _kpis = useMemo(() => {
    const _kpis = [...kpis];
    _kpis.forEach((kpi) => {
      if (kpi.id === statIds(isCurrentSeason).hospitalAdmins) return;
      updateKpiEnhancements({
        kpi,
        seasonStartDate,
        monthlyStats,
        weeklyStats: [statIds(isCurrentSeason).casesNewCount],
        isCurrentSeason,
        isAllTime,
        seasonName,
        vaccineEndDate,
        doseAuthorizationDate
      });
    });
    addSliderVisualization(_kpis.find(({ id }) => id === statIds(isCurrentSeason).hospitalAdmins));
    const deathsKpi = _kpis.find(({ id }) => id === statIds(isCurrentSeason).deathsSeason);
    deathsKpi &&
      (() => {
        deathsKpi.info = (
          <Stack direction="column" gap={1}>
            <Typography variant="body2">
              Why are the death data updated monthly and all the other data updated weekly?
            </Typography>
            <Typography variant="body2">
              Since the end of the public health emergency, DPH has returned to standard data
              release guidelines. To protect patient privacy, we are releasing data about
              COVID-19-related deaths monthly.
            </Typography>
          </Stack>
        );
      })();
    return _kpis.map((kpi) => addLinkOptionsToKpis(kpi, pathname));
  }, [
    kpis,
    isCurrentSeason,
    seasonStartDate,
    isAllTime,
    seasonName,
    vaccineEndDate,
    doseAuthorizationDate,
    pathname
  ]);

  return <DefaultKpis title="Key Indicators" kpis={_kpis} gap={2} />;
};

export default Kpis;
