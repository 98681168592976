"use client";

import React, { ReactNode } from "react";
import { Box, Stack, StackProps } from "@mui/material";

import { defaultGridSpacing, SITE_HEADER_HEIGHT } from "layout/configuration";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import InvestigateMapV3, {
  InvestigateMapV3Props
} from "common/components/InvestigateMap/V2/InvestigateMapV3";

export type InvestigateMapFlexWrapperProps = InvestigateMapV3Props & {
  /**  Element/Component/Node that renders on top of map */
  contentBeforeMap?: React.ReactNode;
  /** Layout direction (e.g. column, row)  */
  direction?: StackProps["direction"];
  /**  Force the children to render at 100% width of parent container */
  fullWidthChildren?: boolean;
  /** Gap space between elements */
  gap?: number;
  children?: ReactNode;
  fromTable?: boolean;
  height?: string;
};

export const InvestigateMapFlexWrapper: React.FC<InvestigateMapFlexWrapperProps> = ({
  children,
  contentBeforeMap,
  gap = 5,
  direction,
  fullWidthChildren = false,
  fromTable,
  height,
  ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <Stack>
      {contentBeforeMap}
      <Box
        display="flex"
        minHeight={600}
        maxHeight={1000}
        height={
          height ?? {
            xs: fromTable
              ? undefined
              : `calc(100vh - 52px - ${
                  parseInt(defaultGridSpacing.md as string) * 2
                }px - ${SITE_HEADER_HEIGHT}px)`,
            md: `calc(100vh - ${SITE_HEADER_HEIGHT * 3}px)`
          }
        }
      >
        <Stack
          gap={isMobile ? 1 : gap}
          flexDirection={direction ?? isMobile ? "column" : "row"}
          flex={1}
          overflow="hidden"
          flexWrap={fullWidthChildren ? "wrap" : "nowrap"}
        >
          <Stack flex={`1 1 ${fullWidthChildren ? "100%" : "50%"}`} flexGrow={0}>
            <InvestigateMapV3 {...props} />
          </Stack>
          <Stack gap={4} flex={`1 1 ${fullWidthChildren ? "100%" : "50%"}`} overflow="hidden">
            {children}
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
};
