import { MouseEvent, useMemo } from "react";
import { Box, Typography, TypographyProps } from "@mui/material";

import { MhcGeographyEnum } from "graphqlApi/types";

import { getReadableGeographyName } from "common/util/geographyHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

interface GeographyListProps {
  availableGeographies: MhcGeographyEnum[];
  setSelectedGranularity?: React.Dispatch<React.SetStateAction<MhcGeographyEnum>>;
}

export const GeographyListV2: React.FC<GeographyListProps> = ({
  availableGeographies,
  setSelectedGranularity
}) => {
  const allGeographies = Object.values(MhcGeographyEnum);

  const { available, notAvailable } = useMemo(() => {
    const available: MhcGeographyEnum[] = [];
    const notAvailable: MhcGeographyEnum[] = [];
    allGeographies.forEach((geography) => {
      if (availableGeographies.includes(geography)) {
        available.push(geography);
      } else {
        notAvailable.push(geography);
      }
    });
    return {
      available: sortGeographiesBySize(available),
      notAvailable: sortGeographiesBySize(notAvailable)
    };
  }, [allGeographies, availableGeographies]);

  const titleProps: TypographyProps = {
    variant: "body2",
    fontWeight: 600,
    lineHeight: "30px"
  };

  const listStyle = {
    paddingLeft: 0,
    listStyle: "none",
    marginTop: 0,
    paddingTop: 0,
    "&:empty": { display: "none" }
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box>
        <Typography {...titleProps}>Available</Typography>
        <ul style={listStyle}>
          {available.map((geography) => (
            <Typography
              data-testid={`geo-list-${geography}`}
              key={geography}
              component="li"
              variant="body2"
              lineHeight={"30px"}
              color="component.links"
              sx={{
                textDecoration: "underline",
                marginLeft: 0,
                paddingLeft: 0,
                paddingTop: 0,
                cursor: "pointer"
              }}
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                setSelectedGranularity?.(geography);
              }}
            >
              {getReadableGeographyName(geography)}
            </Typography>
          ))}
        </ul>
      </Box>
      <Box>
        <Typography {...titleProps}>Not Available</Typography>
        <ul style={listStyle}>
          {notAvailable.map((geography) => (
            <Typography
              key={geography}
              component="li"
              variant="body2"
              lineHeight={"30px"}
              color="light.secondary"
              sx={{ marginLeft: 0, paddingLeft: 0, paddingTop: 0 }}
            >
              {getReadableGeographyName(geography)}
            </Typography>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
