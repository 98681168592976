"use client";

import { AlertTitle, Typography } from "@mui/material";

import { useNavigationStore } from "common/state/useNavigationStore";
import { MhcAlert } from "../Alerts/MhcAlert";

export interface LocationNotAvailableAlertProps {
  locationIsNotSupported?: boolean;
  locationName?: string;
  mt?: string | number;
  nameOfIndicator: string;
  locationId: string;
  showAlert?: boolean;
}

export const LocationNotAvailableAlert: React.FC<LocationNotAvailableAlertProps> = ({
  locationIsNotSupported,
  locationName,
  mt,
  nameOfIndicator
}) => {
  const isNavigating = useNavigationStore((store) => store.isNavigating);

  return (
    <>
      {locationIsNotSupported && !isNavigating && (
        <MhcAlert severity="error" sx={{ mt: mt }} color="error">
          <AlertTitle sx={{ mb: 0 }}>
            {nameOfIndicator} is not available for{" "}
            {locationName === "Unknown" ? "Unknown location" : locationName}
          </AlertTitle>
          <Typography variant="body3">
            Please select a different location using the dropdown or map below.
          </Typography>
        </MhcAlert>
      )}
    </>
  );
};
