import {
  IdentifierConfig,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionConfig
} from "../../dashboard/types";
import { PageConfigurator } from "../types";
import { Maybe, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { chartConfigsForLocationComparison } from "../util";
import { getDatesForDemographicStatQueries } from "../util/demographics";
import { seasonEndsOn } from "common/util/mhcSeason";
import { demographicInvestigateChartConfig } from "modules/Topics/util/config";

export const statIds = {
  deathsSeasonCount: "COVID19_CUMULATIVE_DEATHS_SEASON_COUNT",
  deathsSeasonRate: "COVID19_CUMULATIVE_DEATHS_SEASON_RATE",
  deathsCount: "COVID19_DEATHS_COUNT",
  deathsRate: "COVID19_DEATHS_RATE",
  deathsTotalRate: "COVID19_CUMULATIVE_DEATHS_TOTAL_RATE",
  deathsTotalCount: "COVID19_CUMULATIVE_DEATHS_TOTAL_COUNT"
};

const subSections: PageConfigurator<TopicDashboardSubsectionConfig> = (options) => {
  const { season, isAllTime, isCurrentSeason } = options ?? {};
  let endsOnDateForSeasonallyCumulative: Maybe<string> | Date | undefined = season?.endDate;
  if (!isCurrentSeason) {
    endsOnDateForSeasonallyCumulative = season?.endDate ? seasonEndsOn(season?.endDate) : undefined;
  }
  const { countDates, percentageDates } = getDatesForDemographicStatQueries(
    season,
    endsOnDateForSeasonallyCumulative
  );
  return [
    {
      id: "keyData",
      kpiGroupConfig: {
        statIdConfigs: [
          ...(isAllTime
            ? []
            : [
                {
                  identifier: statIds.deathsSeasonCount,
                  needSeries: true,
                  granularity: "month",
                  startsOn: season?.startDate,
                  endsOn: endsOnDateForSeasonallyCumulative
                } as IdentifierConfig
              ]),
          {
            identifier: statIds.deathsTotalCount,
            needSeries: false,
            startsOn: season?.startDate,
            endsOn: season?.endDate,
            granularity: "month"
          }
        ],
        statIdsWithTrend: [statIds.deathsSeasonCount]
      }
    },
    {
      id: "charts",
      chartConfigs: [
        {
          timeScale: "monthly",
          title: "Monthly deaths",
          type: "line",
          granularity: MhcTimeSeriesGranularityEnum.Month,
          id: "monthly-deaths",
          statIdConfigs: [
            {
              identifier: statIds.deathsRate,
              needSeries: true,
              needStat: false
            }
          ]
        }
      ]
    },
    {
      id: "deaths-by-county",
      title: "COVID-19 Deaths by County",
      chartConfigs: chartConfigsForLocationComparison({
        id: "deaths-by-county",
        identifier: statIds.deathsRate,
        title: ""
      })
    },
    demographicInvestigateChartConfig({
      countStatId: {
        identifier: isAllTime ? statIds.deathsTotalCount : statIds.deathsSeasonCount,
        ...countDates
      },
      comparisonPercentStatId: { identifier: "POPULATION_TOTAL_PERCENT", ...percentageDates }
    })
  ];
};

export const covidDeathsSections: PageConfigurator<TopicDashboardSectionConfig> = (options) => [
  {
    id: "deaths",
    title: "COVID-19 Deaths",
    subSections: subSections(options)
  }
];
