"use client";

import { useSuspenseQuery } from "@apollo/client";

import {
  StatIdentifierDocument,
  StatIdentifierQueryVariables,
  StatIdentifiersDocument,
  StatIdentifiersQueryVariables,
  StatIdentifiersWithAvailableGeosDocument,
  StatIdentifiersWithAvailableGeosQuery,
  StatIdentifiersWithAvailableGeosQueryVariables
} from "graphqlApi/types";

// Stat Identifier
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const useGetMhcStatIdentifier = (variables: StatIdentifierQueryVariables) => {
  return useSuspenseQuery(StatIdentifierDocument, { variables });
};

// Stat Identifier With Available Geos
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const useGetMhcStatIdentifiersWithAvailableGeos = (
  variables: StatIdentifiersWithAvailableGeosQueryVariables
) => {
  return useSuspenseQuery<StatIdentifiersWithAvailableGeosQuery>(
    StatIdentifiersWithAvailableGeosDocument,
    { variables }
  ).data.statIdentifiers;
};

// Stat Identifiers
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const useGetMhcStatIdentifiers = (variables?: StatIdentifiersQueryVariables) => {
  return useSuspenseQuery(StatIdentifiersDocument, { variables });
};
