import compact from "lodash/compact";
import differenceBy from "lodash/differenceBy";
import isNil from "lodash/isNil";
import orderBy from "lodash/orderBy";
import { PointOptionsObject, SeriesBarOptions } from "highcharts";

import { ChartableStat } from "./fetchingFunctions/charts/types";
import { RawChartSeries } from "common/components/charts/Chart/types";
import { DefaultSeries } from "common/components/charts/Investigate/types";

import { demographicColors, DemographicKeys } from "theme/colors";
import { colorFromUnitLabel, NO_DATA_COLOR } from "common/components/charts/util";
import { colorConfig } from "common/components/charts/util/color/config";
import { formatValueByUnit } from "common/util/formatHelpers";

import { fallsIntoOtherGroup } from "./elementHelpers/Covid/common/components/DemographicInvestigateChart";

type CustomPoint = PointOptionsObject & { suppressed?: boolean };

type Value = number | null;
export const getDataWithSuppressedPoints = (series: RawChartSeries[] | Value[]): CustomPoint[] => {
  const values: (number | null | Value[])[] = series.map((obj) => {
    if (isNil(obj)) return null;
    if (typeof obj === "object") return obj.values as Value[];
    return obj as number;
  });
  const returnVs = values.map((obj) => {
    let value = null;
    let suppressed = false;
    if (typeof obj === "object" && (obj?.length ?? 0) > 0) {
      if (isNil(obj)) {
        return null;
      }
      const v: Value[] = obj as unknown as Value[];
      value = v[v.length - 1];
    } else if (typeof obj === "number") {
      value = obj;
    }
    if (value === null) {
      value = 0;
      suppressed = true;
    }
    const point: CustomPoint = {
      y: value,
      suppressed: suppressed
    };
    return point;
  });
  return compact(returnVs);
};

export const getDataLabelsWithSuppression = ({
  isPercent,
  precision
}: {
  isPercent?: boolean;
  precision?: number;
}): SeriesBarOptions["dataLabels"] => {
  return {
    enabled: true,
    formatter: function (label): string {
      if ((this.point as CustomPoint).suppressed === true) {
        label.position = "right";
        return "N/A";
      }
      const returnType = formatValueByUnit({
        value: this.y,
        precision: isPercent ? 1 : precision ?? 0
      });
      // eslint-disable-next-line
      // @ts-ignore - TODO: fix this to inherit type of formatter context
      return `${returnType ?? ""}${isPercent ? "%" : ""}` ?? null;
    }
  };
};

const sortSeries = (series: RawChartSeries[], typeIsAge: boolean) => {
  if (typeIsAge) {
    const ageMap: Record<string, number> = {};
    series.forEach(
      ({ name = "" }, i) =>
        (ageMap[name ?? i] = fallsIntoOtherGroup(name)
          ? 1000 + i
          : parseInt(name.split("-")[0] as string))
    );
    return [
      ...series.sort(
        (a, b) => (ageMap[a.name as string] as number) - (ageMap[b.name as string] as number)
      )
    ];
  } else {
    return orderBy(series, [(s) => s.values?.[s.values.length - 1] ?? -1], ["desc"]);
  }
};

const matchSeriesOrder = (base: RawChartSeries[], toMatch: RawChartSeries[]): RawChartSeries[] => {
  const match: Record<string, number> = {};
  [...toMatch].forEach(({ name = "" }, i) => {
    match[name ?? i] = i + 1;
  });
  const r = [
    ...base.sort(
      (a, b) => (match[a.name as string] as number) - (match[b.name as string] as number)
    )
  ];
  return r;
};

const fillSeriesToMatch = (base: RawChartSeries[], match: RawChartSeries[]): RawChartSeries[] => {
  const copy = [...base];
  const toRemove = differenceBy(base, match, "name").map(({ name }) => name);
  differenceBy(match, base, "name").forEach((series) => {
    return copy.push({
      ...series,
      id: undefined,
      values: [0]
    });
  });
  return copy.filter(({ name }) => !toRemove.includes(name));
};

/**
 * Creates Investigate chart options from a set of chart properties
 * loaded for a demographic stratification.
 *
 * @param props - set of base properties for chart
 *
 * @returns Option for a demographics investigate chart with series and chart overrides
 *
 * @see ChartableStat
 * @see DefaultSeries
 *
 */
export const makeOptionFromDemographicChartableStat = (
  props: ChartableStat
): DefaultSeries<SeriesBarOptions> => {
  const specialOptions = {
    yAxisOptions: {
      min: 0
    }
  };
  const { title, series: _series, subtitle, id, precision } = props;
  const rateAsPercent = subtitle?.includes("rate");
  const typeIsAge = id === "age";
  const isPercent = props.isPercent || rateAsPercent;
  const [series, comparisonSeries] = (() => {
    const nonPopulation = _series.filter(({ id }) => !id?.includes("POPULATION"));
    const population = _series.filter(({ id }) => id?.includes("POPULATION"));
    const series = sortSeries(nonPopulation, typeIsAge);
    const comparison = !isPercent
      ? null
      : matchSeriesOrder(fillSeriesToMatch(population, nonPopulation), series);
    return [series, comparison];
  })();

  let colorKey: DemographicKeys = id as DemographicKeys;
  colorKey === "age" && (colorKey = "ageGroups");

  const dataLabels: SeriesBarOptions["dataLabels"] = getDataLabelsWithSuppression({
    isPercent,
    precision
  });

  // Sort series by values
  // Handle age group separately by parsing the age from the name of series items
  const chartSeries: SeriesBarOptions[] = [
    {
      borderRadius: 3,
      colors: typeIsAge
        ? Object.values(demographicColors[colorKey])
        : series.map(({ name }) => colorFromUnitLabel(name)?.hex ?? NO_DATA_COLOR),
      colorByPoint: true,
      name: title,
      type: "bar",
      data: getDataWithSuppressedPoints(series),
      showInLegend: false,
      dataLabels
    }
  ];

  if (!isNil(comparisonSeries) && comparisonSeries.length > 0) {
    chartSeries.push({
      borderRadius: 3,
      colors: comparisonSeries.flatMap(() => colorConfig.comparison as string),
      color: colorConfig.comparison as string,
      colorByPoint: false,
      name: "Population Total",
      type: "bar",
      data: getDataWithSuppressedPoints(comparisonSeries),
      showInLegend: false,
      dataLabels
    });
  }

  return {
    title,
    series: chartSeries as SeriesBarOptions[],
    chartOptions: (baseOptions) => ({
      ...baseOptions,
      overrideTooltipFormatter: !isNil(props.overrideTooltipFormatter)
        ? props.overrideTooltipFormatter
        : undefined,
      chartOptions: {
        marginRight: 20
      },
      unit: isPercent ? undefined : "cases",
      precision: precision ?? isPercent ? 1 : 0,
      height: undefined,
      isPercent,
      yAxisTitle: props.unitLabel,
      xAxisEnhancements: {
        xCategories: series.map(({ name }) => name) as string[],
        categoryName: title,
        labels: {
          useHTML: true,
          allowOverlap: true,
          style: {
            width: 75,
            textAlign: "right"
          }
        }
      },
      yAxisOptions: {
        ...(isPercent ? specialOptions.yAxisOptions : {}),
        labels: {
          reserveSpace: true,
          align: "center"
        }
      }
    })
  };
};
