import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import times from "lodash/times";

import { DEFAULT_SKELETON_ANIMATION } from "layout/configuration";

import { IndicatorTableContentWrapperFallbackProps } from "common/components/IndicatorTable/IndicatorTableContentWrapper/IndicatorTableContentWrapperFallback";
import Row from "common/components/Row";

const IndicatorTableFallback: React.FC<IndicatorTableContentWrapperFallbackProps> = ({
  rows,
  columns,
  columnWidths = [],
  columnContentWidths = []
}) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            {times(columns, (index) => (
              <TableCell key={index} sx={{ width: columnWidths[index] }}>
                <Row justifyContent="center">
                  <Skeleton
                    variant="text"
                    width={columnContentWidths[index] ?? "100%"}
                    animation={DEFAULT_SKELETON_ANIMATION}
                  />
                </Row>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {times(rows, (index) => (
            <TableRow key={index}>
              {times(columns, (colIndex) => (
                <TableCell key={colIndex} sx={{ width: columnWidths[colIndex] }}>
                  <Row justifyContent="center">
                    <Skeleton
                      variant="rectangular"
                      width={columnContentWidths[colIndex] ?? "100%"}
                      height={40}
                      animation={DEFAULT_SKELETON_ANIMATION}
                    />
                  </Row>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default IndicatorTableFallback;
