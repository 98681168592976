"use client";

import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { amber } from "@mui/material/colors";
import isNil from "lodash/isNil";
import uniqBy from "lodash/uniqBy";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";

import { TopicDashboardChart, TopicDashboardChartGroup } from "../../../dashboard/types";
import { DatasetGroup, DropdownOption } from "common/components/charts/Investigate/types";
import { MhcAttributionFragment } from "graphqlApi/types";
import { ChartableStat } from "modules/Topics/util/fetchingFunctions/charts/types";

import { pointsFromContext } from "common/components/charts/util/tooltip";
import { logWarning } from "common/util/consoleHelpers";
import { makeOptionFromDemographicChartableStat } from "modules/Topics/util/charts";

import { ChartStackAlert } from "common/components/Alerts/ChartStackAlert";
import { IndicatorInvestigateChartProps } from "common/components/charts/Investigate/IndicatorInvestigateChart";
import { InvestigateChart } from "common/components/charts/Investigate/InvestigateChart";
import {
  DemographicTooltipFormatter,
  DemographicTooltipFormatterProps
} from "./DemographicTooltipFormatter";

export type InitialDemographicInvestigateChartProps = {
  props?: TopicDashboardChartGroup["charts"];
  attributions?: Array<Omit<MhcAttributionFragment, "__typename">>;
};

export type DemographicInvestigateChartProps = {
  initialChartProps?: TopicDashboardChartGroup["charts"];
  page: "vaccinations" | "cases" | "deaths";
  season: string;
  isAllTime: boolean;
};

const getChartableStats = (charts: TopicDashboardChart[]) => {
  const chartableStats: { base: ChartableStat[]; comparison?: ChartableStat[] } = { base: [] };
  charts?.forEach((charts) => {
    const isComparison = charts.id.includes("comparison");
    const props = charts.props as unknown as IndicatorInvestigateChartProps;
    const indicators = props.indicators;
    indicators.forEach((indicator) => {
      if (!isNil(indicator.stratificationGroups)) {
        if (isComparison === true) {
          chartableStats.comparison = indicator.stratificationGroups;
        } else {
          chartableStats.base = indicator.stratificationGroups;
        }
      }
    });
  });
  return chartableStats;
};

export const fallsIntoOtherGroup = (name?: string) => {
  return (
    name?.toLowerCase().includes("unknown") ||
    name?.toLowerCase().includes("other") ||
    name?.toLowerCase().includes("declined") ||
    name?.toLocaleLowerCase().includes("not reported") ||
    false
  );
};

const standardizeName = (name?: string) => {
  if (isNil(name)) return undefined;
  if (name.includes("Another")) return "Other";
  if (name.includes("Multiple")) return "Multiple";
  return name;
};

const mergeSeries = (
  chartableStats: ReturnType<typeof getChartableStats>,
  allTime: boolean,
  page: DemographicInvestigateChartProps["page"]
) => {
  const filterOutId =
    allTime === true && page !== "vaccinations" ? "age_0_5_12_18_35_50_65" : "age_0_5_18_35_50_65";
  return chartableStats.comparison
    ?.filter(({ id }) => id !== filterOutId)
    .map((chartStat) => {
      const base = chartableStats.base
        ?.filter(({ id }) => id !== filterOutId)
        ?.find((chart) => chart.id === chartStat.id || chartStat.id.includes(chart.id));
      const newSeries: (typeof chartStat)["series"] = [];
      // Some series might exist in the comparison and some in the base, in order to portray the whole picture
      // all of the unique series must be displayed, otherwise the chart could display a total less to 100%
      // which doesn't make sense.
      const groupedSeries = uniqBy([chartStat.series, base?.series].flat(), (series) =>
        standardizeName(series?.name)
      );
      // Iterate all of the unique series.
      groupedSeries.forEach((series) => {
        if (!isNil(series)) {
          // Check if it's the comparison or the base chart
          const isComparison = series.id?.toLowerCase().includes("population") ?? false;
          // Get the matching series from the other array
          const matchingSeries = (isComparison ? base : chartStat)?.series.find(
            (matchingSeries) => {
              const n = standardizeName(series.name);
              const mn = standardizeName(matchingSeries.name);
              return n === mn;
            }
          );
          // Determine if the series should be added, exceptions are for other groups
          // which values are 0
          let addSeries = false;
          if (fallsIntoOtherGroup(series.name)) {
            if (series.values.filter((value) => !isNil(value) && value !== 0).length > 0) {
              addSeries = true;
            }
          } else {
            addSeries = true;
          }
          if (addSeries === true) {
            newSeries.push({ ...series, name: standardizeName(series.name) });
            if (!isNil(matchingSeries)) {
              newSeries.push({ ...matchingSeries, name: standardizeName(matchingSeries.name) });
            }
          }
        }
      });

      const result = {
        ...base,
        series: newSeries
      };
      return result;
    }) as unknown as ChartableStat[];
};

const DemographicInvestigateChart: React.FC<DemographicInvestigateChartProps> = ({
  initialChartProps,
  season,
  page,
  isAllTime
}) => {
  const DEFAULT_DEMOGRAPHIC_GROUP = "Age";
  const [stratificationGroups, countEmpty]: [ChartableStat[][], boolean] = useMemo(() => {
    const countCharts = initialChartProps?.filter(({ id }) => id.includes("count"));
    const countChartableStats = getChartableStats(countCharts ?? []);

    const percentChartableStats = countChartableStats.base.map((chartableStat) => {
      const seriesTotal: number =
        chartableStat.series
          .map(({ values }) => (values.length > 0 ? values[values.length - 1] : 0))
          .reduce((acc, a) => (acc ?? 0) + (a ?? 0), 0) ?? 1;
      const series = chartableStat.series.map((series) => {
        const value = series.values.length > 0 ? series.values[series.values.length - 1] ?? 0 : 0;
        return {
          ...series,
          values: [(value / seriesTotal) * 100]
        };
      });
      return {
        ...chartableStat,
        isPercent: true,
        series
      };
    });

    const percentageCharts = initialChartProps?.filter(({ id }) => id.includes("percent"));
    const percentageBase = mergeSeries(
      {
        ...getChartableStats(percentageCharts ?? []),
        base: percentChartableStats
      },
      isAllTime,
      page
    );

    const groups = [percentageBase.flat(), countChartableStats.base] as ChartableStat[][];
    return [
      groups,
      countChartableStats.base.flatMap((s) => s.series?.flatMap((s) => s.dates)).length === 0
    ];
  }, [initialChartProps, isAllTime, page]);

  const { name, pluralName } = useMemo(() => {
    switch (page) {
      case "cases":
        return {
          name: "Cases",
          pluralName: "Cases"
        };
      case "deaths":
        return {
          name: "Deaths",
          pluralName: "Deaths"
        };
      case "vaccinations":
        return {
          name: "Vaccination",
          pluralName: "Vaccinations"
        };
    }
  }, [page]);

  if (!stratificationGroups?.length) {
    throw new Error(`No stratification groups found for ${name}ByDemographicGroup chart`);
  }

  const chartTooltipFormatter = (params: DemographicTooltipFormatterProps) => {
    const { points, title, subtitle, ...props } = params;
    const point = points[0];
    const pointLabel = point?.category ?? point?.category ?? "";
    const div = document.createElement("div");
    const root = createRoot(div);
    flushSync(() => {
      root.render(
        <DemographicTooltipFormatter
          title={`${title ?? ""}: ${pointLabel}`}
          subtitle={subtitle}
          points={points}
          {...props}
        />
      );
    });
    return div.innerHTML;
  };

  const groups: DatasetGroup[] = stratificationGroups.map(
    (groupItems: ChartableStat[]): DatasetGroup => {
      const options: DatasetGroup["options"] = {};
      const firstItem = groupItems[0] as ChartableStat;
      if (!firstItem) {
        logWarning("ChartableStat not defined or missing");
      }
      let groupTitle = "";
      let subtitle = firstItem?.subtitle ?? "";
      let isPercent = false;
      switch (true) {
        case firstItem?.isPercent || firstItem?.subtitle?.includes("rate"):
          groupTitle = "Percentage";
          subtitle = `Percentage of ${pluralName}`;
          isPercent = true;
          break;
        default:
          groupTitle = "Count";
      }
      groupItems.reduce((opts: DatasetGroup["options"], item) => {
        // eslint-disable-next-line
        // @ts-ignore - TODO: fix this - I think DatasetGroup["options"] can be undefined so it's not happy with the reduce
        opts[item.title] = makeOptionFromDemographicChartableStat({
          ...item,
          subtitle,
          unitLabel: subtitle.toLowerCase(),
          overrideTooltipFormatter: function () {
            return chartTooltipFormatter({
              points: pointsFromContext(this),
              title: item.title,
              subtitle: season,
              unit: isPercent === true ? "percent" : "count",
              precision: isPercent ? 1 : 0,
              percent: isPercent,
              page: pluralName
            });
          }
        });
        return opts;
      }, options);

      return {
        default: groupTitle === "Percentage",
        defaultOption: options[DEFAULT_DEMOGRAPHIC_GROUP] as DropdownOption,
        groupTitle,
        options,
        subtitle
      };
    }
  );

  if (countEmpty === true) {
    return <ChartStackAlert />;
  }

  return (
    <InvestigateChart
      title={`COVID-19 ${pluralName}`}
      groups={groups}
      immutable={true}
      investigationLabels={["Show Data as", "Select demographic"]}
      investigationIsOptional={false}
      hideGroupTitle={true}
      hideClearSelection={true}
      investigationBanner={
        <Typography
          variant="body2"
          sx={{
            width: "fit-content",
            p: 1.25,
            pr: 6,
            background: amber["50"],
            borderRadius: "5px"
          }}
        >
          Select COVID-19 {name} data to explore
        </Typography>
      }
    />
  );
};

export default DemographicInvestigateChart;
