"use client";

import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcStatIdentifier } from "graphqlApi/types";

import { KPI, KpiProps } from "common/components/KPI";
import {
  InvestigateMapFlexWrapper,
  InvestigateMapFlexWrapperProps
} from "../Wrapper/InvestigateMapFlexWrapper";

export interface InvestigateMapWithStateKpisProps extends InvestigateMapFlexWrapperProps {
  kpis?: KpiProps[];
  contentBeforeKpis?: React.ReactNode;
  contentAfterKpis?: React.ReactNode;
}

export const InvestigateMapWithStateKpis: React.FC<InvestigateMapWithStateKpisProps> = ({
  kpis,
  contentBeforeKpis,
  contentAfterKpis,
  ...props
}) => {
  const [selectedStat, setSelectedStat] = useState<MhcStatIdentifier | undefined>();
  return (
    <InvestigateMapFlexWrapper onSelectedStatChange={setSelectedStat} {...props}>
      <Stack height="100%" gap={2} flexGrow={1}>
        {!isNil(selectedStat?.description) && (
          <Stack gap={0} direction="column">
            <Typography component="span" fontWeight={600}>
              About this indicator
            </Typography>
            <Typography>{selectedStat?.description}</Typography>
          </Stack>
        )}
        {contentBeforeKpis}
        {kpis?.map((kpiProps, index) => (
          <KPI
            key={index}
            {...kpiProps}
            index={index}
            sx={{ height: "fit-content", overflow: "visible" }}
          />
        ))}
        {contentAfterKpis}
      </Stack>
    </InvestigateMapFlexWrapper>
  );
};
