import { useMemo } from "react";
import dynamic from "next/dynamic";

import { StackedColumnChartProps } from "./util/stackedColumnChartTypes";

// dynamic component to get so highcharts resizes correctly
export const DynamicStackedColumnChart: React.FC<StackedColumnChartProps> = (props) => {
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/charts/StackedColumnChart"), {
        loading: () => <div>Loading...</div>,
        ssr: false
      }),
    [
      /* list variables which should trigger a re-render here */
    ]
  );
  return <Component {...props} />;
};
