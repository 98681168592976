import { Stack, Typography } from "@mui/material";

import { TooltipFormatterParams, TooltipPoint } from "common/components/charts/util/tooltip";

import ChartTooltipPoint from "common/components/charts/ChartTooltip/ChartTooltipPoint";

export interface DemographicTooltipFormatterProps
  extends Pick<TooltipFormatterParams, "percent" | "precision" | "unit"> {
  title: string;
  subtitle: string;
  /** List of point info from Highcharts  */
  points: TooltipPoint[];
  page: string;
}

export const DemographicTooltipFormatter = ({
  title,
  subtitle,
  points,
  page,
  ...props
}: DemographicTooltipFormatterProps) => {
  return (
    <Stack gap={0.75}>
      <Stack direction="column" gap={0.5}>
        {title && (
          <Typography variant="body3" fontWeight={600} lineHeight={1}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="caption" fontWeight={400} lineHeight={1}>
            {subtitle}
          </Typography>
        )}
      </Stack>
      {points.length > 0 && (
        <Stack gap={0.5}>
          {points.map((point, i) => {
            const seriesName = point.series.name.toLowerCase().includes("total")
              ? "Percentage of Total Population"
              : `Percentage of ${page}`;
            const percentName = props.percent === true ? seriesName : `Count of ${page}`;
            return (
              <ChartTooltipPoint
                point={point}
                key={`chart-point-${i}-${point.point.index as number}`}
                overrideSeriesName={percentName}
                {...props}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
