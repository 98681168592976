"use client";

import React, { useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";
import { flushSync } from "react-dom";
import { createRoot } from "react-dom/client";
import { tickInterval } from "common/components/charts/highchartsConfig";

import { MhcAttributionFragment } from "graphqlApi/types";

import { pointsFromContext } from "common/components/charts/util/tooltip";
import { getFullSeasonName, seasonIsAllTime } from "common/util/mhcSeason";

import { ContentCardTextBlock, ContentCardTextBlockProps } from "layout/card/ContentCardTextBlock";
import AboutTheDataAccordion from "../../common/components/AboutTheDataAccordion";
import HowToUseDateSlider from "../../common/components/HowToUseDateSlider";
import ChartTooltipContent from "common/components/charts/ChartTooltip/ChartTooltipContent";
import { DynamicStackedColumnChart } from "common/components/charts/DynamicStackedColumnChart";
import { InSectionBorderedWrapper } from "common/components/InSectionBorderedWrapper/InSectionBorderedWrapper";
import { PageProps } from "../Content";
import { CasesAdditionalData } from "../getData";

type VariantProportionsProps = {
  season: PageProps["season"];
  series: CasesAdditionalData["variantSeries"];
  attributions?: MhcAttributionFragment[] | null;
  locationName: string;
};

const VariantProportions: React.FC<VariantProportionsProps> = ({
  season,
  series,
  attributions,
  locationName
}) => {
  const isAllTimeView = seasonIsAllTime(season);
  const title = useMemo(
    () => `COVID-19 Variant Proportions (${getFullSeasonName(season)})`,
    [season]
  );
  const commonAboutProps: ContentCardTextBlockProps = {
    variant: "h5",
    titleProps: {
      fontWeight: 700
    },
    sx: { mb: 0 }
  };
  const Chart = useMemo(() => {
    if (isNil(series)) return null;
    const selectedRangeButton = (() => {
      const sixMonths = 6 * 4 - 1;
      if (isNil(series?.series) || series?.series.length === 0) return 3;
      const _series = series?.series[0];
      if (isNil(_series)) return 3;
      const dataLen = _series?.data?.length ?? 0;
      if (dataLen >= sixMonths) return 1;
      return 3;
    })();
    return (
      <DynamicStackedColumnChart
        {...series}
        title={title}
        subtitle={getFullSeasonName(season)}
        timeSeries={true}
        height={isAllTimeView ? 600 : 400}
        granularity="week"
        xAxisAreDates={true}
        useRangeSelectorButtons={isAllTimeView}
        useNavigator={isAllTimeView}
        useScrollbar={isAllTimeView}
        overrideDefaultRangeSelected={selectedRangeButton}
        overrideTooltipFormatter={function () {
          const div = document.createElement("div");
          const root = createRoot(div);
          flushSync(() => {
            root.render(
              <ChartTooltipContent
                points={pointsFromContext(this)}
                percent={true}
                precision={series.precision}
                title={series.tooltipOptions?.title ?? series.title}
                granularity="week"
                xAxisEnhancements={{
                  xCategories: series.categories,
                  xCategoriesReadableDict: series.categoriesReadableDict
                }}
              />
            );
          });
          return div.innerHTML;
        }}
        customNavigatorOptions={{
          adaptToUpdatedData: false,
          series: {
            marker: { enabled: false },
            lineWidth: 0,
            fillColor: "transparent",
            color: "transparent"
          },
          yAxis: {
            labels: { enabled: false }
          },
          xAxis: {
            labels: { enabled: false },
            minTickInterval: tickInterval("monthly")
          }
        }}
      />
    );
  }, [isAllTimeView, season, series, title]);

  return (
    <InSectionBorderedWrapper title={title} subtitle={locationName}>
      {series && Chart}
      <Stack gap={2}>
        {isAllTimeView && <HowToUseDateSlider />}
        <AboutTheDataAccordion
          id="covid-cases-chart-additional-info"
          subtitle="Variant Proportions"
          attributions={attributions ?? []}
          uiLocation="covid-cases-chart-additional-info"
        >
          <ContentCardTextBlock title="Ways to interpret the data" {...commonAboutProps}>
            <Typography>
              The colors on the above graph each represent a particular variant appearing in
              week&apos;s COVID-19 test results analyzed for sequencing. The height of the colored
              section reflects the number of times that variant was observed. In other words, the
              taller the section, the greater the number of times that variant appeared. The shorter
              the section, the fewer the number of times that variant was observed in test results.
            </Typography>
            <Typography>
              The &quot;Other&quot; category encompasses all variations that each constitute less
              than 5% of the total, combining them into a single group for simplified analysis and
              reporting.
            </Typography>
          </ContentCardTextBlock>
          <ContentCardTextBlock title="Why doesn't our data match the CDC?" {...commonAboutProps}>
            <Typography>
              Our data is based on test results sequenced in Delaware laboratories while the CDC
              data is surveyed from labs throughout the United States. The variants most dominant in
              our region may not be the same as in other parts of the country.
            </Typography>
          </ContentCardTextBlock>
        </AboutTheDataAccordion>
      </Stack>
    </InSectionBorderedWrapper>
  );
};

export default VariantProportions;
