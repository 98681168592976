import isNil from "lodash/isNil";

import {
  IdentifierConfig,
  TopicDashboardChartSectionConfig,
  TopicDashboardSubsectionConfig
} from "./elementHelpers/dashboard/types";

/**
 * Creates a config for a demographic investigate chart
 * Which ultimately loads chart props for the percentage and count stats
 *
 * @param params
 * @param params.countStatId - config associated with the *count* identifier/stats
 * @param params.comparisonPercentStatId - config associated with the comparison *percentage* identifier/stats
 *
 * @returns config object for the demographic investigate chart
 *
 * @see IdentifierConfig
 * @see TopicDashboardSubsectionConfig
 *
 * @throws Error if the config intended to load percent stat is not percent/rate and
 *         if the config associated with the count is not a count identifier
 *
 */
export const demographicInvestigateChartConfig = ({
  countStatId,
  comparisonPercentStatId
}: {
  countStatId: IdentifierConfig;
  comparisonPercentStatId?: IdentifierConfig;
}): TopicDashboardSubsectionConfig => {
  if (!countStatId.identifier.match(/COUNT/g))
    throw new Error(
      `${countStatId.identifier} is an invalid id for count stat identifier - needs to be count`
    );
  const baseChartConfigs: TopicDashboardChartSectionConfig[] | undefined = [
    {
      id: "count",
      title: "",
      type: "indicator-investigation",
      includeStratifications: true,
      stratificationsAsDropdown: true,
      statIdConfigs: [
        {
          ...countStatId,
          needStat: true,
          needSeries: false,
          needStratifications: true
        }
      ]
    }
  ];
  if (!isNil(comparisonPercentStatId)) {
    baseChartConfigs.push({
      id: "comparison_percent_1",
      title: "",
      type: "indicator-investigation",
      includeStratifications: true,
      stratificationsAsDropdown: true,
      statIdConfigs: [
        {
          ...comparisonPercentStatId,
          needStat: true,
          needSeries: true,
          needStratifications: true
        }
      ]
    });
  }
  return {
    id: "investigateChart",
    chartConfigs: baseChartConfigs
  };
};
