import pick from "lodash/pick";

import {
  IdentifierConfig,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionConfig
} from "../../dashboard/types";
import { PageConfigurator } from "../types";
import {
  MhcGeographyEnum,
  MhcLocationFragment,
  MhcTimeSeriesGranularityEnum
} from "graphqlApi/types";

import { getDatesForDemographicStatQueries } from "../util/demographics";
import { lastWeekDates } from "common/util/date";
import { seasonEndsOn } from "common/util/mhcSeason";
import { demographicInvestigateChartConfig } from "modules/Topics/util/config";

import { SeasonForPage } from "modules/Topics/pages/items/CovidConfig";

const granularity = MhcTimeSeriesGranularityEnum.Week;
const defaultStatIdConfig = {
  needSeries: true,
  granularity
};

export const statIds = {
  cumulativeSeasonRate: "COVID19_CUMULATIVE_CASES_SEASON_RATE",
  cumulativeSeason: "COVID19_CUMULATIVE_CASES_SEASON_COUNT",
  casesNewRate: "COVID19_CASES_RATE",
  casesRate: "COVID19_CASES_CRUDE_RATE",
  casesCount: "COVID19_CASES_COUNT",
  casesTotalRate: "COVID19_CUMULATIVE_CASES_TOTAL_RATE",
  casesTotalCount: "COVID19_CUMULATIVE_CASES_TOTAL_COUNT",
  variantPercent: "COVID19_VARIANT_PERCENT"
};

type GetInvestigateMapConfig = {
  season: SeasonForPage | null | undefined;
  lastUpdatedOn?: string;
  location: MhcLocationFragment;
};
export const getInvestigateMapConfig = ({
  season,
  lastUpdatedOn,
  location
}: GetInvestigateMapConfig) => {
  const seasonIsCurrent = season?.isCurrent ?? false;
  const seasonIsAllTime = season?.isAllTime ?? false;
  const mapGranularity = MhcTimeSeriesGranularityEnum.Week;
  let mappableIdentifiers = ["cumulativeSeason"];
  seasonIsCurrent && (mappableIdentifiers = ["casesCount"]);
  seasonIsAllTime && (mappableIdentifiers = ["casesTotalCount"]);
  const mappableStatIds = Object.values(pick(statIds, mappableIdentifiers));

  const mapDates =
    seasonIsCurrent || seasonIsAllTime
      ? lastWeekDates(lastUpdatedOn)
      : lastWeekDates(season?.endDate as string);

  return {
    map: {
      locationId: location.id,
      omitGeographies: [MhcGeographyEnum.Municipality, MhcGeographyEnum.State],
      stats: mappableStatIds.map((identifier) => ({
        ...mapDates,
        identifier,
        granularity: mapGranularity
      }))
    },
    kpis: {
      locationGeography: location.geography,
      locationId: location.id,
      locationName: location.name,
      kpiGroupConfig: {
        statIdConfigs: [
          {
            needSeries: false,
            identifier: mappableStatIds[0] as string,
            granularity:
              seasonIsCurrent || seasonIsAllTime
                ? MhcTimeSeriesGranularityEnum.Week
                : MhcTimeSeriesGranularityEnum.Month,
            startsOn: season?.startDate,
            endsOn: seasonEndsOn(season?.endDate, 0)
          }
        ]
      }
    }
  };
};

const subSections: PageConfigurator<TopicDashboardSubsectionConfig> = (options) => {
  const { season, isAllTime, isCurrentSeason } = options ?? {};
  const endsOn = seasonEndsOn(season?.endDate);
  const { countDates, percentageDates } = getDatesForDemographicStatQueries(season);

  const kpiStatIdConfigs: IdentifierConfig[] = [];
  if (isAllTime) {
    kpiStatIdConfigs.push({
      identifier: statIds.casesTotalCount,
      ...defaultStatIdConfig,
      needSeries: false
    });
  } else {
    kpiStatIdConfigs.push({
      identifier: statIds.cumulativeSeason,
      granularity: "week",
      startsOn: season?.startDate,
      endsOn
    });
    isCurrentSeason &&
      kpiStatIdConfigs.push({
        identifier: statIds.cumulativeSeason,
        ...defaultStatIdConfig,
        fourWeekAverage: true
      });
  }

  return [
    {
      id: "keyData",
      kpiGroupConfig: {
        statIdConfigs: kpiStatIdConfigs
      }
    },
    demographicInvestigateChartConfig({
      countStatId: {
        identifier: isAllTime ? statIds.casesTotalCount : statIds.cumulativeSeason,
        ...countDates
      },
      comparisonPercentStatId: { identifier: "POPULATION_TOTAL_PERCENT", ...percentageDates }
    })
  ];
};

export const covidCasesSections: PageConfigurator<TopicDashboardSectionConfig> = (options) => [
  {
    id: "cases",
    title: "COVID-19 Cases",
    subSections: subSections(options)
  }
];
