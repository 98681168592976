"use client";

import { useMemo, useState } from "react";
import { Grid, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcGeographyEnum, MhcStatIdentifier } from "graphqlApi/types";

import { DEFAULT_COLUMN_SPACING } from "layout/configuration";

import { ContentCard } from "layout/card/ContentCard";
import InvestigateMapV3, {
  InvestigateMapV3Props
} from "common/components/InvestigateMap/V2/InvestigateMapV3";
import {
  LocationNotAvailableAlert,
  LocationNotAvailableAlertProps
} from "common/components/LocationNotAvailableAlert/LocationNotAvailableAlert";
import { GeographyListV2 } from "./GeographyList";

export interface OverviewMapLocationSelectorProps {
  appendToDescription?: React.ReactNode;
  locationIsNotSupported?: boolean;
  contentCardTitle?: string;
  locationNotAvailableAlertProps?: LocationNotAvailableAlertProps | null;
  nameOfIndicatorOrSection?: string;
  nodeAboveCard?: React.ReactNode;
  showAlert?: boolean;
  investigateMapProps: InvestigateMapV3Props;
  contentCardClassName?: string;
  contentCardHeaderVariant?: "secondary" | "primary" | undefined;
  title?: string;
  description?: string;
}

export const OverviewMapLocationSelector: React.FC<OverviewMapLocationSelectorProps> = ({
  appendToDescription,
  locationIsNotSupported,
  contentCardTitle,
  locationNotAvailableAlertProps,
  nameOfIndicatorOrSection: overrideNameOfIndicatorOrSection,
  nodeAboveCard,
  showAlert,
  investigateMapProps,
  contentCardClassName,
  contentCardHeaderVariant = "secondary",
  title,
  description
}) => {
  const availableGeographies = investigateMapProps.availableGeographies ?? [];
  const [selectedGeography, setSelectedGeography] = useState<MhcGeographyEnum>(
    availableGeographies?.length > 0 ? availableGeographies[0] ?? "state" : "state"
  );
  const [selectedSi, setSelectedSi] = useState<MhcStatIdentifier | undefined>(undefined);

  const loadedContentCardTitle = useMemo(() => {
    if (selectedSi === undefined && contentCardTitle === undefined) {
      return "";
    }
    if (selectedSi) {
      return selectedSi.name;
    }
    if (contentCardTitle) {
      return contentCardTitle;
    }
    return "";
  }, [selectedSi, contentCardTitle]);

  const nameOfIndicatorOrSection = useMemo(() => {
    if (!isNil(overrideNameOfIndicatorOrSection)) return overrideNameOfIndicatorOrSection;
    return selectedSi?.name ?? "";
  }, [overrideNameOfIndicatorOrSection, selectedSi?.name]);

  // TODO: Maybe use the new Investigate Map Base Flex Wrapper?
  // The only problem I found is that the Content card generates some weird padding/margins
  // that prevent the layout from looking the same.
  return (
    <>
      {showAlert && locationIsNotSupported === true && (
        <>
          {locationNotAvailableAlertProps && (
            <LocationNotAvailableAlert {...locationNotAvailableAlertProps} />
          )}
          {nodeAboveCard && nodeAboveCard}
        </>
      )}
      <ContentCard
        headerVariant={contentCardHeaderVariant}
        title={loadedContentCardTitle}
        className={contentCardClassName ?? "web-only-no-pdf"}
        sx={{ position: "relative", pb: 4 }}
      >
        <Grid container sx={{ pb: 4 }} spacing={DEFAULT_COLUMN_SPACING}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ height: { xs: "auto", md: "750px" }, pb: 5, flexGrow: 1, display: "flex" }}
          >
            <InvestigateMapV3
              {...investigateMapProps}
              overrideSelectedGeography={selectedGeography}
              overrideSetSelectedGeography={setSelectedGeography}
              onSelectedStatChange={setSelectedSi}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: { xs: -6, md: "auto" } }}>
            <Typography component="p" variant="h3">
              {title ?? "About this indicator"}
            </Typography>
            {!isNil(description) && (
              <Typography component="p" variant="body2">
                {description}
              </Typography>
            )}
            <Typography component="p" variant="h4" sx={{ mt: "25px " }}>
              Available Geographies
            </Typography>
            <Typography component="p" variant="body2" sx={{ mt: "10px" }}>
              {nameOfIndicatorOrSection} data are available for the following geographies. Selecting
              a geography outside of this group will result in data not being available or being
              shown at an available geography.
            </Typography>
            <GeographyListV2
              availableGeographies={availableGeographies}
              setSelectedGranularity={setSelectedGeography}
            />
            {appendToDescription && appendToDescription}
          </Grid>
        </Grid>
      </ContentCard>
    </>
  );
};
