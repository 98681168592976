"use client";

import { Stack } from "@mui/material";

import { MhcAttributionFragment } from "graphqlApi/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { ContentCardSectionTitle } from "layout/card/ContentCardSectionTitle";
import { KPI, KpiProps } from "common/components/KPI";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";

export type CovidKpiProps = {
  attributions?: MhcAttributionFragment[];
  title?: string;
  kpis: KpiProps[];
  direction?: "row" | "column";
  gap?: number;
};

const Kpis: React.FC<CovidKpiProps> = ({
  title,
  kpis,
  attributions = [],
  direction = "column",
  gap = 4
}) => {
  const isMobile = useIsMobile();
  return (
    <Stack>
      {title && <ContentCardSectionTitle title={title} />}
      <Stack gap={gap} direction={isMobile ? "column" : direction} flexWrap="wrap">
        {kpis.map((kpiProps, index) => (
          <KPI
            valueSize="large"
            {...kpiProps}
            key={`kpi-${index}`}
            sx={{
              mb: 0,
              flex: 1,
              maxWidth: kpis.length === 1 ? "600px" : undefined
            }}
          />
        ))}
      </Stack>
      {attributions.length > 0 && (
        <AttributionPopover attributions={attributions} uiLocation={title} />
      )}
    </Stack>
  );
};

export default Kpis;
