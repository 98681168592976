import React from "react";
import { Box, Stack } from "@mui/material";

import { MhcNote, MhcSeason } from "graphqlApi/types";

import Note from "common/components/Alerts/Note";
import ReportDates, { ReportDatesProps } from "common/components/ReportDates/ReportDates";
import { TopicContentCard } from "modules/Topics/components/TopicContentCard";
import SeasonPicker from "modules/Topics/components/TopicDashboard/SeasonSelector";

interface CovidPageTopicContentCardWrapperProps {
  title: string;
  id: string;
  locationId: string;
  season: MhcSeason | null;
  seasons?: MhcSeason[];
  dates: ReportDatesProps;
  children?: React.ReactNode;
  notes: MhcNote[] | null;
}

export const CovidPageTopicContentCardWrapper: React.FC<CovidPageTopicContentCardWrapperProps> = ({
  children,
  dates,
  id,
  locationId,
  notes,
  season,
  seasons,
  title
}) => {
  return (
    <Stack gap={4}>
      {notes && notes.length > 0 && (
        <Stack gap={1}>
          {notes.map((note) => (
            <Note {...note} key={note.title} />
          ))}
        </Stack>
      )}
      <TopicContentCard title={title} id={id} contentGap={0}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          gap={{ xs: 2, lg: 4 }}
        >
          <Box sx={{ minWidth: { xs: "100%", lg: "500px" } }}>
            <SeasonPicker
              seasons={seasons}
              pageId={id}
              locationId={locationId}
              selectedSeason={season}
            />
          </Box>
          <ReportDates {...dates} />
        </Stack>
        {children}
      </TopicContentCard>
    </Stack>
  );
};
