"use client";

import { useSuspenseQuery } from "@apollo/client";

import {
  LocationsForMapsDocument,
  LocationsForMapsQueryVariables,
  MapDocument,
  MapQuery,
  MapQueryVariables,
  MapWithStatsDocument,
  MapWithStatsQueryVariables
} from "graphqlApi/types";

// Map Data With Stats
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
export const useGetMhcMapDataWithStats = (variables: LocationsForMapsQueryVariables) => {
  return useSuspenseQuery(LocationsForMapsDocument, { variables });
};

export const useMhcMap = (variables: MapQueryVariables) => {
  return useSuspenseQuery<MapQuery>(MapDocument, { variables }).data.map;
};

export const useMhcMapWithStats = (variables: MapWithStatsQueryVariables) => {
  return useSuspenseQuery(MapWithStatsDocument, { variables });
};
