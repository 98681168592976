import isNil from "lodash/isNil";

import {
  MhcFeatureCollection,
  MhcGeoJsonFeatureProperty,
  MhcLocationStat,
  MhcMapFeatureCollection,
  MhcMapProperties,
  MhcMapStat
} from "graphqlApi/types";

import { formatValueByUnit } from "common/util/formatHelpers";

import { DataTableProps } from "common/components/DataTable";

export interface BaseGeoJsonToDataProps {
  geoJson: MhcFeatureCollection | MhcMapFeatureCollection;
  selectedStatId: string;
  version?: 2 | 3;
}

export interface StandardMapDataTableRow {
  id: string;
  name: string;
  value: React.ReactNode | number | null;
  valueNumber: number | null;
}

export const geoJsonToDataForSelectedStat = ({
  geoJson: { features = [] },
  selectedStatId,
  version = 2
}: BaseGeoJsonToDataProps): DataTableProps<StandardMapDataTableRow>["data"] => {
  const data: DataTableProps<StandardMapDataTableRow>["data"] = [];
  features?.forEach((feature, index) => {
    const stats = feature?.properties?.stats as MhcLocationStat[] | MhcMapStat[];
    const stat = stats?.find(
      (stat) =>
        ((stat as MhcMapStat).identifier || (stat as MhcLocationStat).statIdentifier.id) ===
        selectedStatId
    );

    const baseProperty: StandardMapDataTableRow = {
      id: `${
        (feature?.properties as MhcGeoJsonFeatureProperty)?.id ??
        (feature?.properties as MhcMapProperties).slug ??
        index
      }`,
      name: feature.properties?.name ?? "",
      value: null,
      valueNumber: null
    };
    if (!isNil(stats) && !isNil(stat) && stats.length > 0) {
      if (version === 2) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { unit, precision, isPercent, value } = stat as any;
        const formattedValue = formatValueByUnit({
          value,
          unit,
          precision,
          isPercent
        });
        baseProperty.value = formattedValue;
        baseProperty.valueNumber = value;
      } else {
        baseProperty.value = (stat as MhcMapStat).displayValue ?? null;
        baseProperty.valueNumber = (stat as MhcMapStat).value ?? null;
      }
    }
    data.push(baseProperty);
  });
  return data;
};
