import isNil from "lodash/isNil";

import { Maybe, MhcSeason } from "graphqlApi/types";

import { LAST_CENSUS_YEAR } from "common/constants";

export const getDatesForDemographicStatQueries = (
  season?: MhcSeason | null,
  endsOn?: Maybe<string>,
  vaccines?: boolean
) => {
  if (!season) {
    return { countDates: {}, percentageDates: {} };
  }
  const startsOn = vaccines === true ? season.startDateForVaccine : season?.startDate;
  const _endsOn = endsOn ?? vaccines === true ? season.endDateForVaccine : season?.endDate;
  const countDates = { startsOn, endsOn: _endsOn };
  const startYear: string = !isNil(startsOn) ? startsOn?.split("-")?.[0] ?? "2010" : "2010";
  const endYear = endsOn?.split("-")?.[0] ?? new Date().getFullYear().toString();
  const percentageDates = {
    startsOn:
      Number(startYear) > LAST_CENSUS_YEAR ? `${LAST_CENSUS_YEAR}-01-01` : `${startYear}-01-01`,
    endsOn: Number(endYear) < LAST_CENSUS_YEAR ? `${LAST_CENSUS_YEAR}-12-31` : `${endYear}-12-31`
  };

  return { countDates, percentageDates };
};
