import { Stack, Typography } from "@mui/material";

import { MhcGeographyEnum, MhcStatIdentifier } from "graphqlApi/types";

import { formatValueByUnit } from "common/util/formatHelpers";
import { getReadableGeographyName } from "common/util/geographyHelpers";

import { DataTableProps } from "common/components/DataTable";
import { SuppressionTooltipNA } from "common/components/SuppressionTooltip/SuppressionTooltip";
import { StandardMapDataTableRow } from "./geoJsonToDataForSelectedStat";

export interface BaseColumnConfigurationProps {
  geography: MhcGeographyEnum;
  statIdentifier: MhcStatIdentifier;
}

export const columnsFromSelectedStatAndGeography = ({
  geography,
  statIdentifier
}: BaseColumnConfigurationProps): DataTableProps<StandardMapDataTableRow>["columns"] => {
  return [
    {
      id: "name",
      display: getReadableGeographyName(geography, true),
      render: (row) => row.name
    },
    {
      id: "value",
      display: (
        <Stack>
          <Typography component="span" variant="body2" fontWeight={600}>
            {statIdentifier.name ?? "Value"}
          </Typography>
          {(statIdentifier.subtitle || statIdentifier.statCaption) && (
            <Typography component="span" variant="body3" fontWeight={400}>
              {statIdentifier.subtitle ?? statIdentifier.statCaption}
            </Typography>
          )}
        </Stack>
      ),
      info: statIdentifier.description ?? statIdentifier.fullName,
      render: (row) => {
        if (row.valueNumber) {
          return (
            <>
              {formatValueByUnit({
                value: row.valueNumber,
                unit: statIdentifier.unit,
                precision: statIdentifier.precision,
                isPercent: statIdentifier.isPercent
              })}
            </>
          );
        } else if (row.value) {
          return <>{row.value}</>;
        } else {
          return <SuppressionTooltipNA />;
        }
      },
      align: "right"
    }
  ];
};
