import isNil from "lodash/isNil";

import { MhcSeason, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { formatDateByGranularity } from "common/util/formatHelpers";
import { getUtcDateFromString } from "common/util/utcDateFromString";

import { KpiProps } from "common/components/KPI";
import { DOSE_AUTHORIZATION_DATE, statIds as vaccineStatIds } from "../Vaccines/config";
import { getDataDatePeriod } from ".";

type UpdateKpiEnhancementsParams = {
  kpi: KpiProps;
  seasonName?: string;
  seasonStartDate: MhcSeason["startDate"];
  monthlyStats?: string[];
  weeklyStats?: string[];
  removePercentChangeStatement?: boolean;
  isCurrentSeason?: boolean;
  isAllTime?: boolean;
  doseAuthorizationDate?: string | undefined | null;
  vaccineEndDate?: string | undefined | null;
};

/**
 * Update kpi.enhancement.dataDatePeriod
 *
 * @param params
 * @param params.kpi
 * @param params.seasonName
 * @param params.seasonStartDate
 * @param params.monthlyStats
 * @param params.weeklyStats
 * @param params.removePercentChangeStatement
 * @param params.isCurrentSeason
 * @param params.isAllTime
 * @param params.doseAuthorizationDate
 * @param params.vaccineEndDate
 */
export const updateKpiEnhancements = ({
  kpi,
  seasonStartDate,
  seasonName,
  monthlyStats,
  weeklyStats,
  removePercentChangeStatement = true,
  isCurrentSeason = true,
  isAllTime = false,
  doseAuthorizationDate = DOSE_AUTHORIZATION_DATE,
  vaccineEndDate
}: UpdateKpiEnhancementsParams) => {
  if (isNil(kpi.value)) {
    kpi.enhancement = {
      ...kpi.enhancement,
      dataDatePeriod: ""
    };
    return;
  }
  const isCumulative = kpi.id?.includes("CUMULATIVE");
  const isSeason = kpi.id?.includes("SEASON");
  const isWeekly =
    (weeklyStats && weeklyStats?.includes(kpi?.id ?? "")) || (!isCumulative && !isSeason);

  // Remove percent change statement
  if (removePercentChangeStatement) {
    kpi.percentSentenceProps = null;
  }

  let dataDatePeriod = "";

  // If dataDatePeriod is () the stat is likely suppressed so no need to update
  if (kpi.enhancement?.dataDatePeriod === "()") {
    kpi.enhancement = {
      ...kpi.enhancement,
      dataDatePeriod
    };
    return;
  }

  // Add "Last Week" if weekly stat and current season
  if (isWeekly && isCurrentSeason) {
    // Prevent adding "Last Week" on subsequent calls based on state changes
    if (kpi.enhancement?.dataDatePeriod?.includes("Last Week")) return;
    kpi.enhancement = {
      ...kpi.enhancement,
      dataDatePeriod: `Last Week ${kpi.enhancement?.dataDatePeriod ?? ""}`.trimEnd()
    };
    return;
  }

  // Update dataDatePeriod
  switch (true) {
    case [
      vaccineStatIds.vaccinatedCount,
      vaccineStatIds.vaccinatedPercent,
      vaccineStatIds.vaccinatedCountV2,
      vaccineStatIds.vaccinatedPercentV2
    ].includes(kpi.id ?? ""):
      if (isCurrentSeason === true || isAllTime) {
        dataDatePeriod = getDataDatePeriod({
          start: doseAuthorizationDate as string, // The type needed to be null | string to allow use of GQL type. But it has a default value.
          end: kpi.enhancement?.date,
          granularity: MhcTimeSeriesGranularityEnum.Week
        });
        dataDatePeriod = `Since the new vaccine was authorized ${dataDatePeriod}`;
      } else if (!isNil(vaccineEndDate)) {
        const date = getUtcDateFromString(vaccineEndDate) as Date;
        dataDatePeriod = `By the end of the season (${formatDateByGranularity({
          value: date,
          granularity: MhcTimeSeriesGranularityEnum.Week
        })})`;
      }
      break;
    case monthlyStats?.includes(kpi.id as string):
      dataDatePeriod = getDataDatePeriod({
        start: seasonStartDate as string,
        end: kpi.enhancement?.date,
        granularity: MhcTimeSeriesGranularityEnum.Month
      });
      break;
    default:
      dataDatePeriod = getDataDatePeriod({
        start: seasonStartDate as string,
        end: kpi.enhancement?.date,
        granularity: MhcTimeSeriesGranularityEnum.Week
      });
  }

  // Update season name
  if (typeof kpi.title === "string" && kpi.title.match(/This Season/gi) && !isCurrentSeason) {
    seasonName &&
      (kpi.title = (kpi.title as string).replace(
        /\(This Season\)|This Season/gi,
        `(${seasonName})`
      ));
  }

  if (kpi.suppressed === true || isNil(kpi.value)) {
    dataDatePeriod = "";
  }

  kpi.enhancement = {
    ...kpi.enhancement,
    dataDatePeriod
  };
};
