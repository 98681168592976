import { Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { getDataDatePeriod } from "../util";
import { calculateSliderIndicatorPosition } from "common/components/Ranges/util/slider";
import { formatValueByUnit } from "common/util/formatHelpers";
import {
  DeltaLabelEnum,
  determineRangeValuePhrase,
  formattedRangeLevelValueRange,
  RangeLevelEnum
} from "common/util/formatHelpers/statIdentifierHelpers/ranges";
import calculatePercentChange from "common/util/percentChange";

import { KpiProps } from "common/components/KPI";
import { KPI_ADDITIONAL_INFO_LINE_HEIGHT } from "common/components/KPI/KpiAdditionalInformation";
import { AdditionalVisualizationProps } from "common/components/KPI/KpiAdditionalVisualization";
import { StyledList } from "common/components/lists/StyledList";
import { statIds } from "./config";

/**
 * Adds viewMore enhancement options
 *
 * @param kpi - KPI properties/options
 * @param currentPathname
 *
 * @returns Kpi props with viewMore enhancement options
 *
 */
export const addLinkOptionsToKpis = (kpi: KpiProps, currentPathname = ""): KpiProps => {
  const kpiId = kpi.id ?? "";
  let viewMoreDescription = null;
  let viewMoreUrl = null;
  const updateViewMoreProps = (page: string, pluralize = false, path?: string) => {
    const _path = path ?? `${page}${pluralize ? "s" : ""}`;
    viewMoreDescription = `View more ${page} data`;
    viewMoreUrl = currentPathname.replace("covid-19/overview/", `covid-19/${_path}/`);
  };
  switch (true) {
    case kpiId.includes("CASE"):
      updateViewMoreProps("case", true);
      break;
    case kpiId.includes("HOSPITAL"):
      updateViewMoreProps("hospitalization", true);
      break;
    case kpiId.includes("VACCIN"):
      updateViewMoreProps("vaccination", false, "vaccines");
      break;
    case kpiId.includes("DEATH"):
      updateViewMoreProps("death", true);
      break;
    default:
      viewMoreDescription = null;
      viewMoreUrl = currentPathname;
      break;
  }
  kpi.enhancement = {
    ...kpi.enhancement,
    viewMoreDescription,
    viewMoreUrl,
    viewMoreShowWhenSuppressed: true
  };
  return kpi;
};

/**
 * Add Slider visualization to kpi
 *
 * @param kpi - KPI properties/options
 *
 */
export const addSliderVisualization = (kpi?: KpiProps | null) => {
  if (!kpi) return;
  const { timeSeries } = kpi?.enhancement?.trendProps ?? {};
  type TimeSeriesValue = number | null;
  let percentChange: TimeSeriesValue = null;
  let formattedPercentChange = "";
  const vizOpts = { value: 0, min: -30, max: 30, asPercentage: false };
  let tooltipTitle = "";

  if (!timeSeries || timeSeries.values.length <= 2) return;

  const { values, dates } = timeSeries;
  percentChange = calculatePercentChange({
    firstValue: values[values.length - 2] as number,
    lastValue: values[values.length - 1] as number
  });

  if (!percentChange) return;

  vizOpts.value = percentChange;
  formattedPercentChange = formatValueByUnit({
    value: percentChange,
    precision: 1,
    isPercent: true
  });
  tooltipTitle =
    percentChange === 0
      ? "No change in hospital admissions from prior week"
      : `${formattedPercentChange} ${
          percentChange > 0 ? "increase" : "decrease"
        } in hospital admissions from prior week`;

  kpi.enhancement = {
    ...kpi?.enhancement,
    additionalVisualizationProps: {
      type: "slider",
      colorSet: "decreasingIncreasing",
      rawValue: percentChange,
      value: formattedPercentChange,
      options: {
        // I won't update the function, since the different vacc stats are temporary.
        // and the hospitalizations stat isn't affected by the current season
        indicatorName: kpi.id ?? statIds(false).hospitalAdmins,
        labels: ["Decreasing", "Stable", "Increasing"],
        leftValue: 0,
        rightValue: 100,
        selectedValue: calculateSliderIndicatorPosition(vizOpts) as number,
        tooltipTitle,
        sectionWidths: [20, 20, 40, 20, 20],
        sectionLabels: Object.entries(DeltaLabelEnum).map(
          ([k, v]) =>
            `${v} (${formattedRangeLevelValueRange()[k as keyof typeof DeltaLabelEnum] as string})`
        )
      }
    } as AdditionalVisualizationProps
  };

  const changePhrase = determineRangeValuePhrase({
    value: percentChange as number,
    type: "delta"
  });

  // Get the date of the week before the latest date
  const latestDate = dates?.[dates.length - 2];
  let prevWeekDate: null | string = null;
  if (latestDate) {
    prevWeekDate = getDataDatePeriod({
      end: latestDate,
      granularity: "week",
      asParenthetical: false
    });
  }

  kpi.additionalInformation = (
    <Typography fontSize="inherit" lineHeight={KPI_ADDITIONAL_INFO_LINE_HEIGHT}>
      <Typography fontSize="inherit" fontWeight={700} component="span" lineHeight="inherit">
        {changePhrase?.replace(/ *\([^)]*\) */g, ` (${formattedPercentChange})`) ?? ""}
      </Typography>
      {!changePhrase?.includes(RangeLevelEnum.Stable) && (
        <Typography component="span" fontSize="inherit" lineHeight="inherit">
          {" "}
          of new COVID-19 hospital admissions compared to the week before{" "}
          {!isNil(prevWeekDate) && `(${prevWeekDate})`}.
        </Typography>
      )}
    </Typography>
  );

  // Remove percentSentenceProps in favor of the added visualization and additional information
  kpi.percentSentenceProps = null;

  // I won't update the function, since the different vacc stats are temporary.
  // and the hospitalizations stat isn't affected by the current season
  if (kpi.id === statIds(false).hospitalAdmins) {
    const paragraphSx = {
      fontSize: "inherit",
      lineHeight: 1.3,
      mb: 2
    };
    const listItemSx = {
      ...paragraphSx,
      mb: 0
    };
    kpi.info = (
      <>
        <Typography sx={paragraphSx}>
          Percent change in the current weekly total new admissions of patients with
          laboratory-confirmed COVID-19 per 100,000 population compared with the prior week.
        </Typography>
        <StyledList>
          {Object.entries(DeltaLabelEnum).map(([key, label]) => {
            const valueLabel =
              formattedRangeLevelValueRange()?.[key as keyof typeof DeltaLabelEnum] ?? "";
            return (
              <Typography component="li" sx={listItemSx} key={key}>
                {label} {valueLabel && `(${valueLabel})`}
              </Typography>
            );
          })}
        </StyledList>
      </>
    );
  }
};

export const addLastWeekLabel = (
  kpi: KpiProps,
  isCurrentSeason: boolean,
  seasonName: string
): KpiProps => {
  if (kpi.id?.toLowerCase().includes("cumulative")) return kpi;
  const isAllSeason = seasonName.toLowerCase().includes("all");
  if (isCurrentSeason === false || isAllSeason === false) return kpi;
  return {
    ...kpi,
    enhancement: {
      ...(kpi.enhancement ?? {}),
      dataDatePeriod: `Last ${kpi.enhancement?.granularity ?? "week"} ${
        kpi.enhancement?.dataDatePeriod ?? ""
      }`
    }
  };
};
