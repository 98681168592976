"use client";

import { ReactNode } from "react";
import { Box, BoxProps, Stack, Typography } from "@mui/material";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { HotjarSurveyLink } from "../HotjarSurveyLink/HotjarSurveyLink";

export type InSectionBorderedWrapperProps = Pick<BoxProps, "sx"> & {
  title?: ReactNode;
  subtitle?: ReactNode;
  children: React.ReactNode;
  /** Survey link for hotjar */
  hotjarSurveyLink?: string;
  hotjarSurveyTitle?: string;
};

export const InSectionBorderedWrapper: React.FC<InSectionBorderedWrapperProps> = ({
  title,
  subtitle,
  children,
  hotjarSurveyLink,
  hotjarSurveyTitle,
  sx
}) => {
  const isMobile = useIsMobile();
  return (
    <Stack
      gap={{ xs: 3, md: 4.75 }}
      sx={{
        padding: {
          md: "40px 40px 20px",
          xs: "20px 0px"
        },
        background: "white",
        border: {
          md: "1px solid #E0E0E0",
          xs: "0px solid #E0E0E0"
        },
        borderStyle: "solid",
        borderColor: "#E0E0E0",
        borderTopWidth: {
          md: "1px",
          xs: "0px"
        },
        borderBottomWidth: {
          xs: "1px"
        },
        borderLeftWidth: {
          md: "1px",
          xs: "0px"
        },
        borderRightWidth: {
          md: "1px",
          xs: "0px"
        },
        borderRadius: {
          md: "4px",
          xs: "0px"
        },
        ...sx
      }}
    >
      {(title || subtitle) && (
        <Box component="header">
          {title && (
            <Typography variant="h4" component="h4">
              {title}
            </Typography>
          )}
          <Stack direction={isMobile === true ? "column" : "row"} mt={1} gap={5}>
            {subtitle && <Typography>{subtitle}</Typography>}
            {hotjarSurveyLink && (
              <Stack
                alignContent="end"
                justifyContent="end"
                ml={isMobile === false ? "auto" : undefined}
              >
                <HotjarSurveyLink href={hotjarSurveyLink} title={hotjarSurveyTitle ?? undefined} />
              </Stack>
            )}
          </Stack>
        </Box>
      )}
      {children}
    </Stack>
  );
};
