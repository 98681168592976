"use client";

import { useCallback, useEffect, useRef } from "react";
import { dateTimeLabelFormats, tickInterval } from "./highchartsConfig";
import {
  Chart,
  PlotBarOptions,
  PlotColumnOptions,
  PointAccessibilityOptionsObject,
  RangeSelectorButtonsOptions,
  SeriesLineOptions,
  setOptions,
  YAxisOptions
} from "highcharts";
import Highcharts from "highcharts/highstock";
import highchartsAccessibility from "highcharts/modules/accessibility";
import { HighchartsReact } from "highcharts-react-official";

import { TimeScale } from "./types";
import { MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { defaultChartColorSet } from "./util/color/config";
import { xDateFormat } from "./util/date";
import { ExportingConfigration } from "./util/exportingConfiguration";
import { rangeSelectorButtons, rangeSelectorInputDateFormat } from "./util/rangeSelector";
import { titleHtmlText } from "./util/titleHtmlText";
import { defaultTooltipOptions } from "./util/tooltip";
import highchartsAccessibilityOptions from "common/components/charts/util/highchartsAccessibilityOptions";

import { ChartStackAlert } from "../Alerts/ChartStackAlert";

if (typeof window !== "undefined") {
  highchartsAccessibility(Highcharts);
}

export interface ColumnChartDatum {
  name?: string;
  y: number | null;
  x?: number | string;
  color?: SeriesLineOptions["color"];
  accessibility?: PointAccessibilityOptionsObject;
}

export interface ColumnChartProps {
  title?: string;
  hideTitleOnUI?: boolean;
  subtitle?: string;
  dateString?: string;
  width?: number;
  height?: number;
  maxValue?: number;
  series: {
    color?: string;
    data: ColumnChartDatum[];
    seriesName?: string;
    options?: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      custom?: Record<string, any>;
    };
  }[];
  percent?: boolean;
  legendEnabled?: boolean;
  valuesDescription?: string | null;
  valueSuffix?: string;
  highlightIfIncluded?: string;
  precision?: number;
  timeScale?: TimeScale;
  xAxisTitle?: string;
  yAxisOptions?: YAxisOptions;
  customPlotOptions?: PlotColumnOptions | PlotBarOptions;
  useRangeSelector?: boolean;
  useRangeSelectorButtons?: boolean;
  useNavigator?: boolean;
  useScrollbar?: boolean;
  granularity?: MhcTimeSeriesGranularityEnum;
  overrideTooltipFormatter?: Highcharts.TooltipFormatterCallbackFunction;
  customRangeSelectiorButtons?: RangeSelectorButtonsOptions[];
  overrideXLabelFormatter?: Highcharts.AxisLabelsFormatterCallbackFunction;
  overrideXDateFormat?: string;
  matchTickPositions?: boolean;
  additionalXAxisConfig?: Highcharts.XAxisOptions;
  overrideRangeSelectorSelected?: number;
}

export const axisMaxValue = (series: ColumnChartDatum[], padding = 0) => {
  return Math.max(...series.map(({ y = 0 }) => y ?? 0)) + padding;
};

export const ColumnChart: React.FC<ColumnChartProps> = ({
  additionalXAxisConfig = {},
  title = "",
  hideTitleOnUI = true,
  dateString,
  subtitle = "",
  width,
  height,
  series,
  percent = false,
  legendEnabled = false,
  valuesDescription = "",
  valueSuffix,
  highlightIfIncluded = "zip",
  precision = 2,
  maxValue,
  timeScale,
  xAxisTitle,
  customPlotOptions = {},
  yAxisOptions,
  useRangeSelector,
  useRangeSelectorButtons,
  useNavigator,
  useScrollbar,
  granularity,
  overrideTooltipFormatter,
  overrideXLabelFormatter,
  customRangeSelectiorButtons,
  overrideXDateFormat,
  matchTickPositions,
  overrideRangeSelectorSelected
}) => {
  const chartRef = useRef<Chart>();

  const setChart = useCallback((chart: Chart) => {
    chartRef.current = chart;
  }, []);

  useEffect(() => chartRef.current?.reflow(), []);

  if (series.every((s) => (s?.data?.length ?? 0) <= 1)) {
    return <ChartStackAlert />;
  }

  let timeScaleOptions: Highcharts.XAxisOptions = {};
  const { labels: additionalXAxisLabelsConfig, ..._additionalXAxisConfig } = additionalXAxisConfig;
  if (timeScale) {
    timeScaleOptions = {
      type: "datetime",
      dateTimeLabelFormats,
      minTickInterval: tickInterval(timeScale),
      labels: {
        rotation: -45,
        enabled: true,
        style: {
          color: "black"
        },
        formatter: overrideXLabelFormatter
          ? overrideXLabelFormatter
          : ({ value }) => {
              return Highcharts.dateFormat(
                overrideXDateFormat !== undefined ? overrideXDateFormat : xDateFormat(granularity),
                typeof value === "number" ? value : 0
              );
            },
        ...additionalXAxisLabelsConfig
      },
      ..._additionalXAxisConfig
    };
  } else {
    timeScaleOptions = {
      categories: series[0]?.data.map((d, i) => d.name ?? `${i}`),
      labels: {
        format: percent ? "<span>{value:,.0f}%</span>" : "<span>{value:,.0f}</span>",
        formatter: ({ value }: { value: string | number }) => {
          if (value.toString().toLowerCase().includes(highlightIfIncluded)) {
            return `<b>${value}</b>`;
          }
          return `${value}`;
        },
        style: {
          color: "#000",
          zIndex: -1
        },
        ...additionalXAxisLabelsConfig
      },
      ..._additionalXAxisConfig
    };
  }

  const plotOptions = {
    dataLabels: {
      enabled: true
    },
    maxPointWidth: 50,
    ...customPlotOptions
  };

  const options: Highcharts.Options = {
    accessibility: highchartsAccessibilityOptions(),
    chart: {
      height,
      width,
      backgroundColor: "transparent",
      type: "column"
    },
    title: {
      text: hideTitleOnUI
        ? undefined
        : titleHtmlText({
            title: title ?? "",
            subtitle,
            dateString
          })
    },
    exporting: ExportingConfigration(title, subtitle, dateString),
    xAxis: {
      ...timeScaleOptions,
      title: xAxisTitle
        ? {
            text: xAxisTitle
          }
        : undefined,
      tickPositions:
        matchTickPositions && series && series.length > 0
          ? series[0]?.data.map(({ x }) => (typeof "number" ? (x as number) : 0))
          : undefined,
      lineWidth: 1,
      lineColor: "#000",
      gridLineColor: "#cfcfcf",
      gridLineWidth: 1
    },
    yAxis: {
      tickInterval: maxValue && maxValue < 1 ? 0.5 : undefined,
      tickPixelInterval: 45,
      min: 0,
      max: maxValue ? maxValue : percent === true ? 100 : undefined,
      title: {
        text: valuesDescription,
        align: "middle"
      },
      labels: {
        step: 2,
        format: percent ? "<span>{value:,.0f}%</span>" : "<span>{value:,.0f}</span>",
        useHTML: true,
        style: {
          color: "#000",
          zIndex: -1
        }
      },
      lineWidth: 1,
      lineColor: "#000",
      gridLineColor: "#cfcfcf",
      ...yAxisOptions
    },
    rangeSelector: {
      enabled: useRangeSelector ?? false,
      inputEnabled: true,
      inputDateFormat: rangeSelectorInputDateFormat(granularity),
      buttons:
        useRangeSelectorButtons === true
          ? customRangeSelectiorButtons
            ? customRangeSelectiorButtons
            : rangeSelectorButtons(granularity)
          : [],
      selected: overrideRangeSelectorSelected ? overrideRangeSelectorSelected : 0,
      allButtonsEnabled: true
    },
    navigator: {
      enabled: useNavigator ?? false
    },
    scrollbar: {
      enabled: useScrollbar ?? false
    },
    tooltip: {
      ...defaultTooltipOptions,
      formatter: overrideTooltipFormatter !== undefined ? overrideTooltipFormatter : undefined,
      xDateFormat: "%m/%d/%Y",
      pointFormat:
        percent === true
          ? `{series.name}: <b>{point.y:,.${precision}f}%</b><br/>`
          : `{series.name}: <b>{point.y:,.${precision}f}</b><br/>`,
      shared: true,
      valueSuffix: valueSuffix,
      useHTML: true,
      style: {
        zIndex: 10000
      }
    },
    plotOptions: {
      bar: plotOptions,
      column: plotOptions
    },
    legend: {
      enabled: legendEnabled
    },
    credits: {
      enabled: false
    },
    series: series.map(({ data, seriesName, color, options }) => {
      return {
        custom: {
          ...options?.custom
        },
        name: seriesName,
        borderWidth: 0,
        type: "column",
        id: seriesName,
        color: color,
        dataLabels: {
          enabled: false
        },
        data: data.map((d, i) => ({
          ...d,
          x: typeof d.x === "string" ? new Date(d.x).getTime() : d.x,
          color: color || d.color || defaultChartColorSet[i],
          showInLegend: true,
          accessibility: d.accessibility
        }))
      };
    })
  };
  setOptions({
    lang: {
      thousandsSep: ",",
      numericSymbolMagnitude: 1000,
      decimalPoint: "."
    }
  });
  return <HighchartsReact highcharts={Highcharts} options={options} callback={setChart} />;
};

export default ColumnChart;
