"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link as MuiLink, Stack, Typography } from "@mui/material";

import { MhcAttribution, MhcAttributionFragment } from "graphqlApi/types";

import { CountRateTableRow, TableData } from "../util/getTableData";

import { ChartStackAlert } from "common/components/Alerts/ChartStackAlert";
import { DataTable, DataTableProps } from "common/components/DataTable";
import { SuppressionTooltipNA } from "common/components/SuppressionTooltip/SuppressionTooltip";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";

interface Props {
  rows: CountRateTableRow[];
  title: string;
  date?: string;
  attributions?: (MhcAttributionFragment | MhcAttribution)[];
  statIdentifiers: TableData["statIdentifiers"];
  locationId: string;
}

export const CountRateTable = ({
  rows: _rows,
  title,
  date,
  statIdentifiers,
  attributions = [],
  locationId
}: Props) => {
  const pathname = usePathname() ?? "";

  const rows = _rows.filter((row) => parseInt(row.count) >= 0);

  const columns: DataTableProps<CountRateTableRow>["columns"] = [
    {
      id: "location",
      align: "left",
      display: (() => (
        <Stack>
          <Typography fontWeight={600}>Location</Typography>
        </Stack>
      ))(),
      render: (row) => {
        if (row.id === "unknown" || row.id === locationId)
          return (
            <Typography fontWeight={row.id === "state" ? 600 : 400}>{row.locationName}</Typography>
          );
        return (
          <MuiLink component={Link} href={pathname.replace(locationId, row.id)}>
            <Typography
              fontWeight={row.id === "state" ? 600 : 400}
              sx={{ display: "flex", alignItems: "center", gap: "3px" }}
            >
              {row.locationName}
              <OpenInNewIcon fontSize="inherit" />
            </Typography>
          </MuiLink>
        );
      }
    },
    {
      id: "count",
      align: "center",
      display: (() => (
        <Stack>
          <Typography fontWeight={600}>Count</Typography>
          <Typography variant="caption" fontWeight={400}>
            {statIdentifiers?.count?.subtitle}
          </Typography>
        </Stack>
      ))(),
      render: (row) => <Typography variant="body2">{row.count}</Typography>
    },
    {
      id: "rate",
      align: "center",
      display: (() => (
        <Stack>
          <Typography fontWeight={600}>
            {statIdentifiers?.rate?.isPercent ? "Percentage" : "Rate"}
          </Typography>
          <Typography variant="caption" fontWeight={400}>
            {statIdentifiers?.rate?.statCaption}
          </Typography>
        </Stack>
      ))(),
      render: (row) => {
        if (row.rate === "N/A") return <SuppressionTooltipNA />;
        return <Typography>{row.rate}</Typography>;
      }
    }
  ];

  return (
    <Stack gap={1}>
      <Stack
        sx={({ palette }) => ({
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "5px",
          backgroundColor: palette.brand.light,
          overflow: "hidden"
        })}
      >
        <Stack sx={{ padding: "20px 24px 20px 20px" }} gap={1}>
          <Typography variant="h4">{title}</Typography>
          {date && <Typography variant="body2">{date}</Typography>}
        </Stack>
        {rows && (
          <DataTable
            sxForContainer={{
              borderTopRightRadius: 0,
              borderTopLeftRadius: 0,
              borderRightStyle: "none",
              borderLeftStyle: "none",
              borderBottomStyle: "none"
            }}
            columns={columns}
            data={rows ?? []}
          />
        )}
        {rows.length === 0 && <ChartStackAlert />}
      </Stack>
      <AttributionPopover
        attributions={attributions as MhcAttributionFragment[]}
        sx={{ mt: 0 }}
        uiLocation="Covid Deaths Table"
      />
    </Stack>
  );
};
