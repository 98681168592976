import sortBy from "lodash/sortBy";

import { DataTableProps } from "common/components/DataTable";
import { StandardMapDataTableRow } from "./geoJsonToDataForSelectedStat";

export const standardSortByValue = (
  data: DataTableProps<StandardMapDataTableRow>["data"]
): DataTableProps<StandardMapDataTableRow>["data"] => {
  return sortBy(data, ({ valueNumber: value }) =>
    typeof value === "number" ? (value || 0) * -1 : value
  );
};
