"use client";

import React, { useEffect, useState } from "react";
import { InputLabel, Stack, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { MhcAttributionFragment, MhcLocationFragment } from "graphqlApi/types";

import { legacyCreateInvestigateMapProps } from "common/components/InvestigateMap/V2/util/createInvestigateMapProps/legacy/createInvestigateMapProps";
import { columnsFromSelectedStatAndGeography } from "common/components/InvestigateMap/V2/WithDataTable/util/columnsFromSelectedStatAndGeography";
import {
  geoJsonToDataForSelectedStat,
  StandardMapDataTableRow
} from "common/components/InvestigateMap/V2/WithDataTable/util/geoJsonToDataForSelectedStat";
import { standardSortByValue } from "common/components/InvestigateMap/V2/WithDataTable/util/standardSortByValue";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import { getFullSeasonName } from "common/util/mhcSeason";
import { removeAdditionalInfoFromKpis } from "modules/Topics/util/fetchingFunctions/fetchMapDataV2";
import { fetchKpiGroupProps } from "modules/Topics/util/fetchingFunctions/kpi/fetchKpiGroupProps";

import { MapSectionSkeleton } from "../../common/components/MapSectionSkeleton";
import { InSectionBorderedWrapper } from "common/components/InSectionBorderedWrapper/InSectionBorderedWrapper";
import {
  InvestigateMapWithDataTable,
  InvestigateMapWithDataTableProps
} from "common/components/InvestigateMap/V2/WithDataTable/InvestigateMapWithDataTable";
import { AttributionPopover } from "modules/ECDC/components/AttributionPopover";
import { SeasonForPage } from "modules/Topics/pages/items/CovidConfig";
import { getInvestigateMapConfig } from "../config";

type MapProps = {
  season: SeasonForPage;
  lastUpdatedOn?: string;
  location: MhcLocationFragment;
};
const Map: React.FC<MapProps> = ({ season, lastUpdatedOn, location }) => {
  const isMobile = useIsMobile();
  const { isCurrent: isCurrentSeason = false, isAllTime = false } = season;
  const [props, setMapProps] =
    useState<InvestigateMapWithDataTableProps<StandardMapDataTableRow> | null>(null);
  const [attributions, setAttributions] = useState<MhcAttributionFragment[]>([]);

  useEffect(() => {
    const fetchMapProps = async ({ lastUpdatedOn, season, location }: MapProps) => {
      const config = getInvestigateMapConfig({ lastUpdatedOn, season, location });
      if (isNil(config)) return;
      const mapProps = await legacyCreateInvestigateMapProps(config.map);
      const kpis = await fetchKpiGroupProps(config.kpis);
      if (isNil(mapProps) || isNil(kpis)) return;
      setMapProps({
        ...mapProps,
        kpis: removeAdditionalInfoFromKpis(kpis?.kpis ?? [])
      } as InvestigateMapWithDataTableProps<StandardMapDataTableRow>);
      setAttributions([]);
    };

    void fetchMapProps({ lastUpdatedOn, season, location });
    // setAttributions(mapProps?.attributions ?? []);
  }, [lastUpdatedOn, location, season]);

  const statIdentifier = props?.stats[0];

  if (!props || !statIdentifier) return <MapSectionSkeleton />;

  let dataName = statIdentifier.fullName.replace("(This Season)", "");
  let timeFrame = ` (${season.fullName ?? "This Season"})`;

  if (isAllTime) {
    timeFrame = " (All Time)";
  } else if (isCurrentSeason) {
    timeFrame = " (Last Week)";
  } else {
    dataName = dataName.replace("(this season)", "");
    timeFrame = `(${getFullSeasonName(season)})`;
  }

  const title = `${dataName} ${timeFrame}`.trimEnd();
  const subtitleContext = title.replace(
    timeFrame,
    props?.kpis?.[0]?.enhancement?.dataDatePeriod ?? ""
  );
  const subtitle = `Map of Delaware showing ${subtitleContext}`;

  return (
    <InSectionBorderedWrapper title={title} subtitle={subtitle}>
      <InvestigateMapWithDataTable
        geoJsonToData={geoJsonToDataForSelectedStat}
        configureColumns={columnsFromSelectedStatAndGeography}
        sortFunction={standardSortByValue}
        tableTitle={
          <Stack direction="column" gap={0.2}>
            <InputLabel>Map Results Data Table</InputLabel>
            <Typography variant="body2">Data are sorted from highest to lowest value.</Typography>
          </Stack>
        }
        mapHeight={isMobile ? "50svh" : "100%"}
        {...props}
        contentAfterTable={
          <>
            {!isNil(attributions) && attributions?.length > 0 && (
              <AttributionPopover attributions={attributions} uiLocation="Covid cases map" />
            )}
          </>
        }
      />
    </InSectionBorderedWrapper>
  );
};

export default Map;
