"use client";

import React, { useMemo, useState } from "react";
import { InputLabel } from "@mui/material";
import isNil from "lodash/isNil";

import { DataTableBaseRow } from "common/components/DataTable/types";
import { MhcGeographyEnum } from "graphqlApi/types";

import { BaseColumnConfigurationProps } from "./util/columnsFromSelectedStatAndGeography";
import { BaseGeoJsonToDataProps } from "./util/geoJsonToDataForSelectedStat";

import { DataTable, DataTableProps } from "common/components/DataTable";
import IndicatorTableFallback from "common/components/IndicatorTable/IndicatorTableFallback";
import { InvestigateMapV3Props } from "common/components/InvestigateMap/V2/InvestigateMapV3";
import {
  InvestigateMapWithStateKpis,
  InvestigateMapWithStateKpisProps
} from "../WithStateKpi/InvestigateMapWithStateKpis";

export interface InvestigateMapWithDataTableProps<T extends DataTableBaseRow>
  extends InvestigateMapWithStateKpisProps {
  tableProps?: Omit<DataTableProps<T>, "data" | "columns">;
  tableTitle?: React.ReactNode;
  contentAfterTable?: React.ReactNode;
  geoJsonToData?: (props: BaseGeoJsonToDataProps) => DataTableProps<T>["data"];
  configureColumns?: (props: BaseColumnConfigurationProps) => DataTableProps<T>["columns"];
  sortFunction?: (data: DataTableProps<T>["data"]) => DataTableProps<T>["data"];
}

export const InvestigateMapWithDataTable = <T extends DataTableBaseRow>({
  configureColumns,
  contentAfterTable,
  geoJsonToData,
  sortFunction,
  tableProps: baseTableProps = {},
  tableTitle,
  ...props
}: InvestigateMapWithDataTableProps<T>) => {
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [selectedStatId, setSelectedStatId] = useState<string | undefined>(
    props.defaultStatId ?? undefined
  );
  const [selectedGeography, setSelectedGeography] = useState<MhcGeographyEnum | undefined>(
    props.defaultGeography ?? undefined
  );
  const [geoJsonByGeography, setGeoJsonByGeography] = useState<
    InvestigateMapV3Props["initialGeoJsonByGeography"] | undefined
  >(props.initialGeoJsonByGeography);
  const tableProps: DataTableProps<T> = useMemo(() => {
    let data: DataTableProps<T>["data"] = [];
    let columns: DataTableProps<T>["columns"] = [];
    if (!isNil(selectedGeography) && !isNil(geoJsonByGeography) && !isNil(selectedStatId)) {
      const geoJson = geoJsonByGeography[selectedGeography];
      if (!isNil(geoJson) && !isNil(geoJsonToData)) {
        data = geoJsonToData({ geoJson, selectedStatId });
        if (!isNil(sortFunction)) {
          data = sortFunction(data);
        }
      }
      const stat = props.stats.find((stat) => stat.id === selectedStatId);
      if (!isNil(stat) && !isNil(configureColumns)) {
        columns = configureColumns({ statIdentifier: stat, geography: selectedGeography });
      }
    }
    return {
      ...baseTableProps,
      onRowClick: setSelectedId,
      selectedRowId: selectedId,
      columns,
      data
    };
  }, [
    baseTableProps,
    configureColumns,
    geoJsonByGeography,
    geoJsonToData,
    props.stats,
    selectedGeography,
    selectedId,
    selectedStatId,
    sortFunction
  ]);

  return (
    <InvestigateMapWithStateKpis
      {...props}
      overrideSelectedId={selectedId}
      overrideSetSelectedId={setSelectedId}
      onSelectedGeographyChange={setSelectedGeography}
      onGeoJsonByGeographyChange={setGeoJsonByGeography}
      onSelectedStatIdChange={setSelectedStatId}
      fromTable
      contentAfterKpis={
        <>
          {!isNil(tableTitle) &&
            (typeof tableTitle === "string" ? <InputLabel>{tableTitle}</InputLabel> : tableTitle)}
          {tableProps.data.length === 0 && <IndicatorTableFallback rows={10} columns={2} />}
          {tableProps.data.length > 0 && (
            <DataTable
              {...tableProps}
              sxForContainer={{
                boxShadow: "0 4px 8px -1px rgba(0, 0, 0, 0.1), 0 2px 5px -2px rgba(0, 0, 0, 0.06)",
                minHeight: "500px",
                maxHeight: "500px"
              }}
            />
          )}
          {contentAfterTable}
        </>
      }
    />
  );
};
