"use client";

import React, { useCallback } from "react";
import { useRouter } from "next/navigation";
import { Autocomplete, AutocompleteRenderInputParams } from "@mui/material";

import { MhcSeason } from "graphqlApi/types";

import { getSeasonOptions } from "../../util/elementHelpers/Covid/util";
import { useIsMobile } from "common/util/hooks/useIsMobile";
import useRouterPath from "common/util/hooks/usePathWithParamKeys";
import { getCurrentSeason, getSeasonSlug } from "common/util/mhcSeason";

import { useNavigationStore } from "common/state/useNavigationStore";
import {
  baseAutoCompleteProps,
  baseAutoCompleteSX
} from "common/components/LocationPicker/LocationPicker";
import PickerInput from "common/components/PickerInput/PickerInput";

export type SeasonOption = {
  id: string;
  title: string;
};

type Props = {
  locationId: string;
  pageId: string;
  seasons?: MhcSeason[];
  selectedSeason?: MhcSeason | null;
  disabled?: boolean;
};

const SeasonPicker: React.FC<Props> = ({
  seasons,
  selectedSeason,
  disabled,
  locationId,
  pageId
}) => {
  const _selectedSeason = selectedSeason ?? getCurrentSeason(seasons);
  const isMobile = useIsMobile();
  const router = useRouter();
  const { pathWithParamKeys } = useRouterPath();
  const setIsNavigating = useNavigationStore((store) => store.setIsNavigating);
  const isOptionEqualToValue = (a: SeasonOption, b: SeasonOption) => a.id === b.id;
  const seasonsAsOptions = getSeasonOptions(seasons as MhcSeason[]);
  const selectedSeasonAsOption = getSeasonOptions([
    _selectedSeason
  ] as MhcSeason[])[0] as SeasonOption;
  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => <PickerInput label="Showing data for" {...params} />,
    []
  );

  const handleSeasonChange = useCallback(
    ({ id }: { id: string | null } = { id: null }) => {
      setIsNavigating(true);
      if (!id) return;
      const season = seasons?.find(({ slug }) => id === slug);
      if (!season) throw new Error("Season not available");
      let _pathname = pathWithParamKeys;
      const seasonId = getSeasonSlug(season);
      _pathname = _pathname
        .replace("[locationId]", locationId)
        .replace("[subTopicId]", pageId)
        .replace("[seasonId]", seasonId);
      router.prefetch(_pathname);
      router.push(_pathname);
    },
    [router, pathWithParamKeys, seasons, pageId, locationId, setIsNavigating]
  );

  if (seasons?.length === 0) {
    return null;
  }

  return (
    <Autocomplete
      {...baseAutoCompleteProps}
      options={seasonsAsOptions}
      getOptionLabel={(option: SeasonOption) => option.title}
      disabled={disabled ?? seasons?.length === 1}
      onChange={(_, value) => value?.id && handleSeasonChange(value)}
      sx={{
        ...baseAutoCompleteSX,
        ...{ mb: 0, mt: 0 },
        px: isMobile ? 0 : undefined
      }}
      renderInput={renderInput}
      value={selectedSeasonAsOption}
      isOptionEqualToValue={isOptionEqualToValue}
    />
  );
};

export default SeasonPicker;
